{
    "locale": "sv",
    "languages": {
        "aa": "afar",
        "ab": "abchaziska",
        "ae": "avestiska",
        "af": "afrikaans",
        "ak": "akan",
        "am": "amhariska",
        "an": "aragonska",
        "ar": "arabiska",
        "as": "assamesiska",
        "av": "avariska",
        "ay": "aymara",
        "az": "azerbajdzjanska",
        "ba": "basjkiriska",
        "be": "vitryska",
        "bg": "bulgariska",
        "bh": "bihari",
        "bi": "bislama",
        "bm": "bambara",
        "bn": "bengali",
        "bo": "tibetanska",
        "br": "bretonska",
        "bs": "bosniska",
        "ca": "katalanska",
        "ce": "tjetjenska",
        "ch": "chamorro",
        "co": "korsikanska",
        "cr": "cree",
        "cs": "tjeckiska",
        "cu": "kyrkslaviska",
        "cv": "tjuvasjiska",
        "cy": "kymriska",
        "da": "danska",
        "de": "tyska",
        "dv": "divehi",
        "dz": "dzongkha",
        "ee": "ewe",
        "el": "grekiska",
        "en": "engelska",
        "eo": "esperanto",
        "es": "spanska",
        "et": "estniska",
        "eu": "baskiska",
        "fa": "persiska",
        "ff": "fula",
        "fi": "finska",
        "fj": "fijianska",
        "fo": "färöiska",
        "fr": "franska",
        "fy": "frisiska",
        "ga": "iriska",
        "gd": "gaeliska",
        "gl": "galiciska",
        "gn": "guarani",
        "gu": "gujarati",
        "gv": "manniska",
        "ha": "hausa",
        "he": "hebreiska",
        "hi": "hindi",
        "ho": "hiri motu",
        "hr": "kroatiska",
        "ht": "haitisk kreol",
        "hu": "ungerska",
        "hy": "armeniska",
        "hz": "herero",
        "ia": "interlingua",
        "id": "indonesiska",
        "ie": "interlingue",
        "ig": "igbo",
        "ii": "Nuosu",
        "ik": "iñupiaq",
        "io": "ido",
        "is": "isländska",
        "it": "italienska",
        "iu": "inuktitut",
        "ja": "japanska",
        "jv": "javanesiska",
        "ka": "georgiska",
        "kg": "kikongo",
        "ki": "kikuyu",
        "kj": "kwanyama",
        "kk": "kazakiska",
        "kl": "grönländska",
        "km": "kambodjanska",
        "kn": "kannada",
        "ko": "koreanska",
        "kr": "kanuri",
        "ks": "kashmiri",
        "ku": "kurdiska",
        "kv": "komi",
        "kw": "korniska",
        "ky": "kirgiziska",
        "la": "latin",
        "lb": "luxemburgiska",
        "lg": "luganda",
        "li": "limburgiska",
        "ln": "lingala",
        "lo": "laotiska",
        "lt": "litauiska",
        "lu": "Luba-Katanga",
        "lv": "lettiska",
        "mg": "madagaskiska",
        "mh": "marshallesiska",
        "mi": "maori",
        "mk": "makedonska",
        "ml": "malayalam",
        "mn": "mongoliska",
        "mr": "marathi",
        "ms": "malajiska",
        "mt": "maltesiska",
        "my": "burmesiska",
        "na": "nauriska",
        "nb": "norsk bokmål",
        "nd": "North Ndebele",
        "ne": "nepali",
        "ng": "ndonga",
        "nl": "nederländska",
        "nn": "nynorsk",
        "no": "norska",
        "nr": "South Ndebele",
        "nv": "navajo",
        "ny": "nyanja",
        "oc": "occitanska",
        "oj": "ojibwa",
        "om": "afan oromo",
        "or": "oriya",
        "os": "ossetiska",
        "pa": "punjabi",
        "pi": "pali",
        "pl": "polska",
        "ps": "pashto",
        "pt": "portugisiska",
        "qu": "quechua",
        "rm": "rumantsch",
        "rn": "kirundi",
        "ro": "rumänska",
        "ru": "ryska",
        "rw": "kinyarwanda",
        "sa": "sanskrit",
        "sc": "sardiska",
        "sd": "sindhi",
        "se": "nordsamiska",
        "sg": "sangho",
        "si": "singalesiska",
        "sk": "slovakiska",
        "sl": "slovenska",
        "sm": "samoanska",
        "sn": "shona",
        "so": "somaliska",
        "sq": "albanska",
        "sr": "serbiska",
        "ss": "siswati",
        "st": "sesotho",
        "su": "sundanesiska",
        "sv": "svenska",
        "sw": "swahili",
        "ta": "tamil",
        "te": "telugu",
        "tg": "tadzjikiska",
        "th": "thailändska",
        "ti": "tigrinska",
        "tk": "turkmenska",
        "tl": "tagalog",
        "tn": "setswana",
        "to": "tonganska",
        "tr": "turkiska",
        "ts": "tsonga",
        "tt": "tatariska",
        "tw": "twi",
        "ty": "tahitiska",
        "ug": "uiguriska",
        "uk": "ukrainska",
        "ur": "urdu",
        "uz": "uzbekiska",
        "ve": "venda",
        "vi": "vietnamesiska",
        "vo": "volapük",
        "wa": "vallonska",
        "wo": "wolof",
        "xh": "xhosa",
        "yi": "jiddisch",
        "yo": "yoruba",
        "za": "zhuang",
        "zh": "kinesiska",
        "zu": "zulu"
    }
}
