{
  "locale": "fi",
  "languages": {
    "aa": "afar",
    "ab": "abhaasi",
    "ae": "avesta",
    "af": "afrikaans",
    "ak": "akan",
    "am": "amhara",
    "an": "aragonia",
    "ar": "arabia",
    "as": "assami",
    "av": "avaari",
    "ay": "aimara",
    "az": "azeri",
    "ba": "baškiiri",
    "be": "valkovenäjä",
    "bg": "bulgaria",
    "bh": "bihari",
    "bi": "bislama",
    "bm": "bambara",
    "bn": "bengali",
    "bo": "tiibet",
    "br": "bretoni",
    "bs": "bosnia",
    "ca": "katalaani",
    "ce": "tšetšeeni",
    "ch": "tšamorro",
    "co": "korsika",
    "cr": "cree",
    "cs": "tšekki",
    "cu": "kirkkoslaavi",
    "cv": "tšuvassi",
    "cy": "kymri",
    "da": "tanska",
    "de": "saksa",
    "dv": "divehi",
    "dz": "dzongkha",
    "ee": "ewe",
    "el": "kreikka",
    "en": "englanti",
    "eo": "esperanto",
    "es": "espanja",
    "et": "viro",
    "eu": "baski",
    "fa": "persia",
    "ff": "fulani",
    "fi": "suomi",
    "fj": "fidži",
    "fo": "fääri",
    "fr": "ranska",
    "fy": "länsifriisi",
    "ga": "iiri",
    "gd": "gaeli",
    "gl": "galicia",
    "gn": "guarani",
    "gu": "gudžarati",
    "gv": "manksi",
    "ha": "hausa",
    "he": "heprea",
    "hi": "hindi",
    "ho": "hiri-motu",
    "hr": "kroatia",
    "ht": "haiti",
    "hu": "unkari",
    "hy": "armenia",
    "hz": "herero",
    "ia": "interlingua",
    "id": "indonesia",
    "ie": "interlingue",
    "ig": "igbo",
    "ii": "sichuanin-yi",
    "ik": "inupiaq",
    "io": "ido",
    "is": "islanti",
    "it": "italia",
    "iu": "inuktitut",
    "ja": "japani",
    "jv": "jaava",
    "ka": "georgia",
    "kg": "kongo",
    "ki": "kikuju",
    "kj": "kuanjama",
    "kk": "kazakki",
    "kl": "grönlanti",
    "km": "khmer",
    "kn": "kannada",
    "ko": "korea",
    "kr": "kanuri",
    "ks": "kašmiri",
    "ku": "kurdi",
    "kv": "komi",
    "kw": "korni",
    "ky": "kirgiisi",
    "la": "latina",
    "lb": "luxemburg",
    "lg": "ganda",
    "li": "limburg",
    "ln": "lingala",
    "lo": "lao",
    "lt": "liettua",
    "lu": "katanganluba",
    "lv": "latvia",
    "mg": "malagassi",
    "mh": "marshallese",
    "mi": "maori",
    "mk": "makedonia",
    "ml": "malajalam",
    "mn": "mongoli",
    "mr": "marathi",
    "ms": "malaiji",
    "mt": "malta",
    "my": "burma",
    "na": "nauru",
    "nb": "kirjanorja",
    "nd": "pohjois-ndebele",
    "ne": "nepali",
    "ng": "ndonga",
    "nl": "hollanti",
    "nn": "uusnorja",
    "no": "norja",
    "nr": "etelä-ndebele",
    "nv": "navajo",
    "ny": "njandža",
    "oc": "oksitaani",
    "oj": "odžibwa",
    "om": "oromo",
    "or": "orija",
    "os": "osseetti",
    "pa": "pandžabi",
    "pi": "paali",
    "pl": "puola",
    "ps": "paštu",
    "pt": "portugali",
    "qu": "ketšua",
    "rm": "retoromaani",
    "rn": "rundi",
    "ro": "romania",
    "ru": "venäjä",
    "rw": "ruanda",
    "sa": "sanskrit",
    "sc": "sardi",
    "sd": "sindhi",
    "se": "pohjoissaame",
    "sg": "sango",
    "si": "sinhala",
    "sk": "slovakki",
    "sl": "sloveeni",
    "sm": "samoa",
    "sn": "šona",
    "so": "somali",
    "sq": "albania",
    "sr": "serbia",
    "ss": "swazi",
    "st": "eteläsotho",
    "su": "sunda",
    "sv": "ruotsi",
    "sw": "swahili",
    "ta": "tamili",
    "te": "telugu",
    "tg": "tadžikki",
    "th": "thai",
    "ti": "tigrinja",
    "tk": "turkmeeni",
    "tl": "tagalog",
    "tn": "tswana",
    "to": "tonga",
    "tr": "turkki",
    "ts": "tsonga",
    "tt": "tataari",
    "tw": "twi",
    "ty": "tahiti",
    "ug": "uiguuri",
    "uk": "ukraina",
    "ur": "urdu",
    "uz": "uzbekki",
    "ve": "venda",
    "vi": "vietnam",
    "vo": "volapük",
    "wa": "valloni",
    "wo": "wolof",
    "xh": "xhosa",
    "yi": "jiddiš",
    "yo": "joruba",
    "za": "zhuang",
    "zh": "kiina",
    "zu": "zulu"
  }
}