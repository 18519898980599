{
  "locale": "bg",
  "languages": {
    "aa": "Афарски",
    "ab": "Абхазки",
    "ae": "Авестийски",
    "af": "Африкаанс",
    "ak": "Акан",
    "am": "Амхарски",
    "an": "Арагонски",
    "ar": "Арабски",
    "as": "Асамски",
    "av": "Аварски",
    "ay": "Аймара",
    "az": "Азербайджански",
    "ba": "Башкирски",
    "be": "Белоруски",
    "bg": "Български",
    "bh": "Бихарски езици",
    "bi": "Бислама",
    "bm": "Бамбара",
    "bn": "Бенгалски",
    "bo": "Тибетски",
    "br": "Бретонски",
    "bs": "Босненски",
    "ca": "Каталански",
    "ce": "Чеченски",
    "ch": "Чаморро",
    "co": "Корсикански",
    "cr": "Кри",
    "cs": "Чешки",
    "cu": "Църковнославянски",
    "cv": "Чувашки",
    "cy": "Уелски",
    "da": "Датски",
    "de": "Немски",
    "dv": "Малдивски",
    "dz": "Дзонгкха",
    "ee": "Еве",
    "el": "Гръцки",
    "en": "Английски",
    "eo": "Есперанто",
    "es": "Испански",
    "et": "Естонски",
    "eu": "Баскски",
    "fa": "Персийски",
    "ff": "Фула",
    "fi": "Фински",
    "fj": "Фиджийски",
    "fo": "Фарьорски",
    "fr": "Френски",
    "fy": "Фризийски",
    "ga": "Ирландски",
    "gd": "Шотландски келтски",
    "gl": "Галисийски",
    "gn": "Гуаранийски",
    "gu": "Гуджаратски",
    "gv": "Менски",
    "ha": "Хауса",
    "he": "Иврит",
    "hi": "Хинди",
    "ho": "Хири моту",
    "hr": "Хърватски",
    "ht": "Хаитянски Креолски",
    "hu": "Унгарски",
    "hy": "Арменски",
    "hz": "Хереро",
    "ia": "Интерлингва",
    "id": "Индонезийски",
    "ie": "Интерлингве",
    "ig": "Игбо",
    "ii": "Нуосу",
    "ik": "Инупиак",
    "io": "Идо",
    "is": "Исландски",
    "it": "Италиански",
    "iu": "Инуктитут",
    "ja": "Японски",
    "jv": "Явански",
    "ka": "Грузински",
    "kg": "Конго",
    "ki": "Кикуйю",
    "kj": "Киняма",
    "kk": "Казахски",
    "kl": "Гренландски",
    "km": "Кхмерски",
    "kn": "Каннада",
    "ko": "Корейски",
    "kr": "Канури",
    "ks": "Кашмирски",
    "ku": "Кюрдски",
    "kv": "Коми",
    "kw": "Корнски",
    "ky": "Киргизски",
    "la": "Латински",
    "lb": "Люксембургски",
    "lg": "Луганда (Ганда)",
    "li": "Лимбургски",
    "ln": "Лингала",
    "lo": "Лаоски",
    "lt": "Литовски",
    "lu": "Луба-катанга",
    "lv": "Латвийски",
    "mg": "Малагасийски",
    "mh": "Маршалски",
    "mi": "Маорски",
    "mk": "Македонски",
    "ml": "Малаялам",
    "mn": "Монголски",
    "mr": "Маратхи",
    "ms": "Малайски",
    "mt": "Малтийски",
    "my": "Бирмански",
    "na": "Науруански",
    "nb": "Букмол",
    "nd": "Ндебеле северен",
    "ne": "Непалски",
    "ng": "Ндунга",
    "nl": "Холандски",
    "nn": "Нюношк (Новонорвежки)",
    "no": "Норвежки",
    "nr": "Ндебеле южен",
    "nv": "Навахо",
    "ny": "Нянджа",
    "oc": "Окситански",
    "oj": "Оджибве",
    "om": "Оромо",
    "or": "Одия",
    "os": "Осетински",
    "pa": "Пенджабски",
    "pi": "Пали",
    "pl": "Полски",
    "ps": "Пущунски",
    "pt": "Португалски",
    "qu": "Кечуа",
    "rm": "Романшки",
    "rn": "Рунди",
    "ro": "Румънски",
    "ru": "Руски",
    "rw": "Руанда",
    "sa": "Санскрит",
    "sc": "Сардински",
    "sd": "Синдхи",
    "se": "Северносаамски",
    "sg": "Санго",
    "si": "Синхалски",
    "sk": "Словашки",
    "sl": "Словенски",
    "sm": "Самоански",
    "sn": "Шона",
    "so": "Сомалийски",
    "sq": "Албански",
    "sr": "Сръбски",
    "ss": "Свази",
    "st": "Сото южен",
    "su": "Сундански",
    "sv": "Шведски",
    "sw": "Суахили",
    "ta": "Тамилски",
    "te": "Телугу",
    "tg": "Таджикски",
    "th": "Тайски",
    "ti": "Тигриня",
    "tk": "Туркменски",
    "tl": "Тагалог",
    "tn": "Тсвана",
    "to": "Тонгански",
    "tr": "Турски",
    "ts": "Цонга",
    "tt": "Татарски",
    "tw": "Тви",
    "ty": "Таитянски",
    "ug": "Уйгурски",
    "uk": "Украински",
    "ur": "Урду",
    "uz": "Узбекски",
    "ve": "Венда",
    "vi": "Виетнамски",
    "vo": "Волапюк",
    "wa": "Валонски",
    "wo": "Волоф",
    "xh": "Кхоса",
    "yi": "Идиш",
    "yo": "Йоруба",
    "za": "Чжуански",
    "zh": "Китайски",
    "zu": "Зулу"
  }
}