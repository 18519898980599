{
  "locale": "he",
  "languages": {
    "af": "אפריקאנס",
    "ak": "אקאן",
    "am": "אמהרית",
    "an": "אראגונית",
    "ar": "ערבית",
    "az": "אזרית",
    "be": "בלארוסית",
    "bg": "בולגרית",
    "bn": "בנגלית",
    "br": "ברטונית",
    "bs": "בוסנית",
    "ca": "קטלאנית",
    "co": "קורסיקנית",
    "cs": "צ׳כית",
    "cy": "וולשית",
    "da": "דנית",
    "de": "גרמנית",
    "ee": "אווה",
    "el": "יוונית",
    "en": "אנגלית",
    "eo": "אספרנטו",
    "es": "ספרדית",
    "et": "אסטונית",
    "eu": "בסקית",
    "fa": "פרסית",
    "fi": "פינית",
    "fo": "פארואזית",
    "fr": "צרפתית",
    "fy": "פריזית מערבית",
    "ga": "אירית",
    "gd": "גאלית סקוטית",
    "gl": "גליציאנית",
    "gn": "גוארני",
    "gu": "גוג׳ארטי",
    "ha": "האוסה",
    "he": "עברית",
    "hi": "הינדי",
    "hr": "קרואטית",
    "ht": "קריאולית (האיטי)",
    "hu": "הונגרית",
    "hy": "ארמנית",
    "id": "אינדונזית",
    "ig": "איגבו",
    "is": "איסלנדית",
    "it": "איטלקית",
    "ja": "יפנית",
    "jv": "יאוואית",
    "ka": "גאורגית",
    "kg": "קונגו",
    "kk": "קזחית",
    "km": "חמרית",
    "kn": "קנאדה",
    "ko": "קוריאנית",
    "ku": "כורדית",
    "ky": "קירגיזית",
    "la": "לטינית",
    "lb": "לוקסמבורגית",
    "lg": "גאנדה",
    "ln": "לינגלה",
    "lo": "לאו",
    "lt": "ליטאית",
    "lv": "לטבית",
    "mg": "מלגשית",
    "mi": "מאורית",
    "mk": "מקדונית",
    "ml": "מליאלאם",
    "mn": "מונגולית",
    "mr": "מראטהית",
    "ms": "מלאית",
    "mt": "מלטית",
    "my": "בורמזית",
    "nb": "נורווגית ספרותית",
    "ne": "נפאלית",
    "nl": "הולנדית",
    "nn": "נורווגית חדשה",
    "no": "נורווגית",
    "ny": "ניאנג׳ה",
    "oc": "אוקסיטנית",
    "om": "אורומו",
    "or": "אורייה",
    "pa": "פנג׳אבי",
    "pl": "פולנית",
    "ps": "פאשטו",
    "pt": "פורטוגזית",
    "qu": "קצ׳ואה",
    "rm": "רומאנש",
    "rn": "קירונדי",
    "ro": "רומנית",
    "ru": "רוסית",
    "rw": "קנירואנדית",
    "sd": "סינדהית",
    "si": "סינהלה",
    "sk": "סלובקית",
    "sl": "סלובנית",
    "sm": "סמואית",
    "sn": "שונה",
    "so": "סומלית",
    "sq": "אלבנית",
    "sr": "סרבית",
    "st": "סותו דרומית",
    "su": "סונדנזית",
    "sv": "שוודית",
    "sw": "סווהילי",
    "ta": "טמילית",
    "te": "טלוגו",
    "tg": "טג׳יקית",
    "th": "תאית",
    "ti": "תיגרינית",
    "tk": "טורקמנית",
    "tl": "פיליפינית",
    "tn": "סוואנה",
    "to": "טונגאית",
    "tr": "טורקית",
    "tt": "טטרית",
    "tw": "אקאן",
    "ug": "אויגורית",
    "uk": "אוקראינית",
    "ur": "אורדו",
    "uz": "אוזבקית",
    "vi": "וייטנאמית",
    "wa": "ולונית",
    "wo": "וולוף",
    "xh": "קוסה",
    "yi": "יידיש",
    "yo": "יורובה",
    "zh": "סינית",
    "zu": "זולו"
  }
}