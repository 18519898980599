{
  "locale": "ru",
  "languages": {
    "aa": "афарский",
    "ab": "абхазский",
    "ae": "авестийский",
    "af": "африкаанс",
    "ak": "аканский",
    "am": "амхарский",
    "an": "арагонский",
    "ar": "арабский",
    "as": "ассамский",
    "av": "аварский",
    "ay": "аймара",
    "az": "азербайджанский",
    "ba": "башкирский",
    "be": "белорусский",
    "bg": "болгарский",
    "bh": "бихари",
    "bi": "бислама",
    "bm": "бамбара",
    "bn": "бенгальский",
    "bo": "тибетский",
    "br": "бретонский",
    "bs": "боснийский",
    "ca": "каталанский",
    "ce": "чеченский",
    "ch": "чаморро",
    "co": "корсиканский",
    "cr": "кри",
    "cs": "чешский",
    "cu": "церковнославянский",
    "cv": "чувашский",
    "cy": "валлийский",
    "da": "датский",
    "de": "немецкий",
    "dv": "мальдивский",
    "dz": "дзонг-кэ",
    "ee": "эве",
    "el": "греческий (современный)",
    "en": "английский",
    "eo": "эсперанто",
    "es": "испанский",
    "et": "эстонский",
    "eu": "баскский",
    "fa": "персидский",
    "ff": "фулах",
    "fi": "финский",
    "fj": "фиджийский",
    "fo": "фарерский",
    "fr": "французский",
    "fy": "западный фризийский",
    "ga": "ирландский",
    "gd": "гаэльский",
    "gl": "галицкий",
    "gn": "гуарани",
    "gu": "гуджарати",
    "gv": "мэнский",
    "ha": "хауса",
    "he": "иврит (современный)",
    "hi": "хинди",
    "ho": "хири-моту",
    "hr": "хорватский",
    "ht": "гаитянский креольский",
    "hu": "венгерский",
    "hy": "армянский",
    "hz": "гереро",
    "ia": "интерлингва",
    "id": "индонезийский",
    "ie": "окциденталь",
    "ig": "игбо",
    "ii": "носу",
    "ik": "инупиак",
    "io": "идо",
    "is": "исландский",
    "it": "итальянский",
    "iu": "инуктитут",
    "ja": "японский",
    "jv": "яванский",
    "ka": "грузинский",
    "kg": "конго",
    "ki": "кикуйю",
    "kj": "кваньяма",
    "kk": "казахский",
    "kl": "гренландский",
    "km": "кхмерский язык",
    "kn": "каннада",
    "ko": "корейский",
    "kr": "канури",
    "ks": "кашмирский",
    "ku": "курдский",
    "kv": "коми",
    "kw": "корнский",
    "ky": "киргизский",
    "la": "латинский язык",
    "lb": "люксембургский",
    "lg": "луганда",
    "li": "лимбургский",
    "ln": "лингала",
    "lo": "лаосский",
    "lt": "литовский",
    "lu": "луба-катанга",
    "lv": "латышский",
    "mg": "малагасийский",
    "mh": "маршалльский",
    "mi": "маори",
    "mk": "македонский",
    "ml": "малаялам",
    "mn": "монгольский",
    "mr": "маратхи",
    "ms": "малайский",
    "mt": "мальтийский",
    "my": "бирманский",
    "na": "науруанский",
    "nb": "букмол",
    "nd": "северный ндебеле",
    "ne": "непальский",
    "ng": "ндонга",
    "nl": "голландский",
    "nn": "новонорвежский язык",
    "no": "норвежский",
    "nr": "южный ндебеле",
    "nv": "навахо",
    "ny": "ньянджа",
    "oc": "окцитанский",
    "oj": "оджибва",
    "om": "оромо",
    "or": "ория",
    "os": "осетинский",
    "pa": "панджаби",
    "pi": "пали",
    "pl": "польский",
    "ps": "пушту",
    "pt": "португальский",
    "qu": "кечуа",
    "rm": "ретороманский",
    "rn": "рунди",
    "ro": "румынский",
    "ru": "русский",
    "rw": "киньяруанда",
    "sa": "санскрит",
    "sc": "сардинский",
    "sd": "синдхи",
    "se": "северносаамский",
    "sg": "санго",
    "si": "сингальский",
    "sk": "словацкий",
    "sl": "словенский",
    "sm": "самоанский",
    "sn": "шона",
    "so": "сомали",
    "sq": "албанский",
    "sr": "сербский",
    "ss": "свати",
    "st": "южный сото",
    "su": "суданский",
    "sv": "шведский",
    "sw": "суахили",
    "ta": "тамильский",
    "te": "телугу",
    "tg": "таджикский",
    "th": "тайский",
    "ti": "тигринья",
    "tk": "Ттркменский",
    "tl": "тагальский",
    "tn": "тсвана",
    "to": "тонга",
    "tr": "турецкий",
    "ts": "тсонга",
    "tt": "татарский",
    "tw": "чви",
    "ty": "таитянский",
    "ug": "уйгурский",
    "uk": "украинский",
    "ur": "урду",
    "uz": "узбекский",
    "ve": "венда",
    "vi": "вьетнамский",
    "vo": "волапюк",
    "wa": "валлонский",
    "wo": "волоф",
    "xh": "коса",
    "yi": "идиш",
    "yo": "йоруба",
    "za": "чжуан",
    "zh": "китайский",
    "zu": "зулу"
  }
}