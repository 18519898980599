{
  "locale": "es",
  "languages": {
    "aa": "Afar",
    "ab": "Abjasiano",
    "ae": "Avéstico",
    "af": "Afrikáans",
    "ak": "Akano",
    "am": "Amhárico",
    "an": "Aragonés",
    "ar": "Arabe",
    "as": "Asamés",
    "av": "Avar",
    "ay": "Aimara",
    "az": "Azerí",
    "ba": "Baskir",
    "be": "Bielorruso",
    "bg": "Búlgaro",
    "bh": "Bhoyapurí",
    "bi": "Bislama",
    "bm": "Bambara",
    "bn": "Bengalí",
    "bo": "Tibetano",
    "br": "Bretón",
    "bs": "Bosnio",
    "ca": "Catalán",
    "ce": "Checheno",
    "ch": "Chamorro",
    "co": "Corso",
    "cr": "Cree",
    "cs": "Checo",
    "cu": "Eslavo eclesiástico antiguo",
    "cv": "Chuvasio",
    "cy": "Galés",
    "da": "Danés",
    "de": "Alemán",
    "dv": "Maldivo",
    "dz": "Dzongkha",
    "ee": "Ewé",
    "el": "Griego (moderno)",
    "en": "Inglés",
    "eo": "Esperanto",
    "es": "Español",
    "et": "Estonio",
    "eu": "Euskera",
    "fa": "Persa",
    "ff": "Fula",
    "fi": "Finés",
    "fj": "Fiyiano",
    "fo": "Feroés",
    "fr": "Frances",
    "fy": "Frisón",
    "ga": "Irlandés",
    "gd": "Gaélico escocés",
    "gl": "Gallego",
    "gn": "Guaraní",
    "gu": "Guyaratí",
    "gv": "Manés",
    "ha": "Hausa",
    "he": "Hebreo (moderno)",
    "hi": "Hindi",
    "ho": "Hiri Motu",
    "hr": "Croata",
    "ht": "Haitiano",
    "hu": "Húngaro",
    "hy": "Armenio",
    "hz": "Herero",
    "ia": "Interlingua",
    "id": "Indonesio",
    "ie": "Occidental",
    "ig": "Igbo",
    "ii": "Yi de Sichuán",
    "ik": "Iñupiaq",
    "io": "Ido",
    "is": "Islandés",
    "it": "Italiano",
    "iu": "Inuktitut",
    "ja": "Japonés",
    "jv": "Javanés",
    "ka": "Georgiano",
    "kg": "Kongo",
    "ki": "Kikuyu",
    "kj": "Kuanyama",
    "kk": "Kazajo",
    "kl": "Groenlandés",
    "km": "Camboyano",
    "kn": "Canarés",
    "ko": "Coreano",
    "kr": "Kanuri",
    "ks": "Cachemiro",
    "ku": "Kurdo",
    "kv": "Komi",
    "kw": "Córnico",
    "ky": "Kirguís",
    "la": "Latín",
    "lb": "Luxemburgués",
    "lg": "Luganda",
    "li": "Limburgués",
    "ln": "Lingala",
    "lo": "Lao",
    "lt": "Lituano",
    "lu": "Luba-Katanga",
    "lv": "Letón",
    "mg": "Malgache",
    "mh": "Marshalés",
    "mi": "Maorí",
    "mk": "Macedonio",
    "ml": "Malayalam",
    "mn": "Mongol",
    "mr": "Maratí",
    "ms": "Malayo",
    "mt": "Maltés",
    "my": "Birmano",
    "na": "Nauruano",
    "nb": "Noruego bokmål",
    "nd": "Ndebele del norte",
    "ne": "Nepalí",
    "ng": "Ndonga",
    "nl": "Holandés",
    "nn": "Nynorsk",
    "no": "Noruego",
    "nr": "Ndebele del sur",
    "nv": "Navajo",
    "ny": "Chichewa",
    "oc": "Occitano",
    "oj": "Ojibwa",
    "om": "Oromo",
    "or": "Oriya",
    "os": "Osético",
    "pa": "Panyabí",
    "pi": "Pali",
    "pl": "Polaco",
    "ps": "Pastú",
    "pt": "Portugués",
    "qu": "Quechua",
    "rm": "Romanche",
    "rn": "Kirundi",
    "ro": "Rumano",
    "ru": "Ruso",
    "rw": "Ruandés",
    "sa": "Sánscrito",
    "sc": "Sardo",
    "sd": "Sindhi",
    "se": "Sami septentrional",
    "sg": "Sango",
    "si": "Cingalés",
    "sk": "Eslovaco",
    "sl": "Esloveno",
    "sm": "Samoano",
    "sn": "Shona",
    "so": "Somalí",
    "sq": "Albano",
    "sr": "Serbio",
    "ss": "Suazi",
    "st": "Sesotho",
    "su": "Sundanés",
    "sv": "Sueco",
    "sw": "Suajili",
    "ta": "Tamil",
    "te": "Télugu",
    "tg": "Tayiko",
    "th": "Tailandés",
    "ti": "Tigriña",
    "tk": "Turcomano",
    "tl": "Tagalo",
    "tn": "Setsuana",
    "to": "Tongano",
    "tr": "Turco",
    "ts": "Tsonga",
    "tt": "Tártaro",
    "tw": "Twi",
    "ty": "Tahitiano",
    "ug": "Uigur",
    "uk": "Ucraniano",
    "ur": "Urdu",
    "uz": "Uzbeko",
    "ve": "Venda",
    "vi": "Vietnamita",
    "vo": "Volapük",
    "wa": "Valón",
    "wo": "Wolof",
    "xh": "Xhosa",
    "yi": "Yídish",
    "yo": "Yoruba",
    "za": "Chuan",
    "zh": "Chino",
    "zu": "Zulú"
  }
}
