{
  "locale": "ja",
  "languages": {
    "aa": "アファール語",
    "ab": "アブハズ語",
    "ae": "アベスタン語",
    "af": "アフリカーンス語",
    "ak": "アカン語",
    "am": "アムハラ語",
    "an": "アラゴン語",
    "ar": "アラビア語",
    "as": "アッサム語",
    "av": "アバール語",
    "ay": "アイマラ語",
    "az": "アゼルバイジャン語",
    "ba": "バシキール語",
    "be": "ベラルーシ語",
    "bg": "ブルガリア語",
    "bh": "ビハリ語",
    "bi": "ビスラマ語",
    "bm": "バンバラ語",
    "bn": "ベンガル語",
    "bo": "チベット標準語",
    "br": "ブルトン語",
    "bs": "ボスニア語",
    "ca": "カタロニア語",
    "ce": "チェチェン語",
    "ch": "チャモロ語",
    "co": "コルシカ語",
    "cr": "クリー語",
    "cs": "チェコ語",
    "cu": "教会スラブ語",
    "cv": "チュワシュ語",
    "cy": "ウェールズ語",
    "da": "デンマーク語",
    "de": "ドイツ語",
    "dv": "ディベヒ語",
    "dz": "ゾンカ語",
    "ee": "エウェ語",
    "el": "ギリシャ語",
    "en": "英語",
    "eo": "エスペラント語",
    "es": "スペイン語",
    "et": "エストニア語",
    "eu": "バスク語",
    "fa": "ペルシャ語",
    "ff": "フラニ語",
    "fi": "フィンランド語",
    "fj": "フィジー語",
    "fo": "フェーロー語",
    "fr": "フランス語",
    "fy": "西フリジア語",
    "ga": "アイルランド語",
    "gd": "スコットランドゲール語",
    "gl": "ガリシア語",
    "gn": "グジャラーティー",
    "gu": "グァラニ語",
    "gv": "マン島語",
    "ha": "ハウサ語",
    "he": "ヘブライ語",
    "hi": "ヒンディー語",
    "ho": "ヒリモトゥ語",
    "hr": "クロアチア語",
    "ht": "ハイチ語",
    "hu": "ハンガリー語",
    "hy": "アルメニア語",
    "hz": "ヘレロ語",
    "ia": "インターリンガ語",
    "id": "インドネシア語",
    "ie": "インターリング",
    "ig": "イボ語",
    "ii": "彝語",
    "ik": "イヌピアック語",
    "io": "イド語",
    "is": "アイスランド語",
    "it": "イタリア語",
    "iu": "イヌイット語",
    "ja": "日本語",
    "jv": "ジャワ語",
    "ka": "ジョージア語",
    "kg": "コンゴ語",
    "ki": "キクユ語",
    "kj": "クワニャマ語",
    "kk": "カザフ語",
    "kl": "グリーンランド語",
    "km": "クメール語",
    "kn": "カンナダ語",
    "ko": "韓国語",
    "kr": "カヌリ語",
    "ks": "カシュミール語",
    "ku": "クルド語",
    "kv": "コミ語",
    "kw": "コーンウォール語",
    "ky": "キルギス語",
    "la": "ラテン語",
    "lb": "ルクセンブルク語",
    "lg": "ガンダ語",
    "li": "リンブルガー語",
    "ln": "リンガラ語",
    "lo": "ラオ語",
    "lt": "リトアニア語",
    "lu": "キルバ語",
    "lv": "ラトビア語",
    "mg": "マラガシ語",
    "mh": "マーシャル語",
    "mi": "マオリ語",
    "mk": "マケドニア語",
    "ml": "マラヤーラム語",
    "mn": "モンゴル語",
    "mr": "マラーティー語",
    "ms": "マレー語",
    "mt": "マルタ語",
    "my": "ミャンマー語",
    "na": "ナウル語",
    "nb": "ノルウェー語ブークモール",
    "nd": "北ンデベレ語",
    "ne": "ネパール語",
    "ng": "ンドゥンガ語",
    "nl": "オランダ語",
    "nn": "ニューノシュク",
    "no": "ノルウェー語",
    "nr": "南ンデベレ語",
    "nv": "ナバホ語",
    "ny": "チェワ語",
    "oc": "オック語",
    "oj": "オジブワ語",
    "om": "オロモ語",
    "or": "オリヤー語",
    "os": "オセット語",
    "pa": "パンジャーブ語",
    "pi": "パーリ語",
    "pl": "ポーランド語",
    "ps": "パシュトー語",
    "pt": "ポルトガル語",
    "qu": "ケチュア語",
    "rm": "ロマンシュ語",
    "rn": "ルンディ語",
    "ro": "ルーマニア語",
    "ru": "ロシア語",
    "rw": "キンヤルワンダ語",
    "sa": "サンスクリット語",
    "sc": "サルデーニャ語",
    "sd": "シンド語",
    "se": "北部サーミ語",
    "sg": "サンゴ語",
    "si": "シンハラ語",
    "sk": "スロバキア語",
    "sl": "スロベニア語",
    "sm": "サモア語",
    "sn": "ショナ語",
    "so": "ソマリ語",
    "sq": "アルバニア語",
    "sr": "セルビア語",
    "ss": "スワジ語",
    "st": "南ソト語",
    "su": "スンダ語",
    "sv": "スウェーデン語",
    "sw": "スワヒリ語",
    "ta": "タミル語",
    "te": "テルグ語",
    "tg": "タジク語",
    "th": "タイ語",
    "ti": "ティグリニア語",
    "tk": "トルクメン語",
    "tl": "タガログ語",
    "tn": "ツワナ語",
    "to": "トンガ語",
    "tr": "トルコ語",
    "ts": "ツォンガ語",
    "tt": "タタール語",
    "tw": "チュイ語",
    "ty": "タヒチ語",
    "ug": "ウイグル語",
    "uk": "ウクライナ語",
    "ur": "ウルドゥー語",
    "uz": "ウズベク語",
    "ve": "ベンダ語",
    "vi": "ベトナム語",
    "vo": "ボラピューク語",
    "wa": "ワロン語",
    "wo": "ウォロフ語",
    "xh": "コサ語",
    "yi": "イディッシュ語",
    "yo": "ヨルバ語",
    "za": "チュワン語",
    "zh": "中国語",
    "zu": "ズールー語"
  }
}
