{
  "locale": "cs",
  "languages": {
    "aa": "afarština",
    "ab": "abcházština",
    "ae": "avestánština",
    "af": "afrikánština",
    "ak": "akanština",
    "am": "amharština",
    "an": "aragonština",
    "ar": "arabština",
    "as": "ásámština",
    "av": "avarština",
    "ay": "ajmarština",
    "az": "ázerbájdžánština",
    "ba": "baškirština",
    "be": "běloruština",
    "bg": "bulharština",
    "bh": "bihárština",
    "bi": "bislamština",
    "bm": "bambarština",
    "bn": "bengálština",
    "bo": "tibetština",
    "br": "bretonština",
    "bs": "bosenština",
    "ca": "katalánština",
    "ce": "čečenština",
    "ch": "chamorro",
    "co": "korsičtina",
    "cr": "kríjština",
    "cs": "čeština",
    "cu": "staroslověnština",
    "cv": "čuvaština",
    "cy": "velština",
    "da": "dánština",
    "de": "němčina",
    "dv": "divehi",
    "dz": "dzongkha",
    "ee": "eveština",
    "el": "řečtina",
    "en": "angličtina",
    "eo": "esperanto",
    "es": "španělština",
    "et": "estonština",
    "eu": "baskičtina",
    "fa": "perština",
    "ff": "fulbština",
    "fi": "finština",
    "fj": "fidžijština",
    "fo": "faerština",
    "fr": "francouzština",
    "fy": "západofríština",
    "ga": "irština",
    "gd": "skotská gaelština",
    "gl": "galicijština",
    "gn": "guaraní",
    "gu": "gudžarátština",
    "gv": "manština",
    "ha": "hauština",
    "he": "hebrejština",
    "hi": "hindština",
    "ho": "hiri motu",
    "hr": "chorvatština",
    "ht": "haitština",
    "hu": "maďarština",
    "hy": "arménština",
    "hz": "hererština",
    "ia": "interlingua",
    "id": "indonéština",
    "ie": "interlingue",
    "ig": "igbo",
    "ii": "yi",
    "ik": "inupiaq",
    "io": "ido",
    "is": "islandština",
    "it": "italština",
    "iu": "inuitština",
    "ja": "japonština",
    "jv": "javánština",
    "ka": "gruzínština",
    "kg": "konžština",
    "ki": "kikujština",
    "kj": "kuanyama",
    "kk": "kazaština",
    "kl": "grónština",
    "km": "khmerština",
    "kn": "kannadština",
    "ko": "korejština",
    "kr": "kanurijština",
    "ks": "kašmírština",
    "ku": "kurdština",
    "kv": "komijština",
    "kw": "kornština",
    "ky": "kyrgyzština",
    "la": "latina",
    "lb": "lucemburština",
    "lg": "gandština",
    "li": "limburština",
    "ln": "ngalština",
    "lo": "laoština",
    "lt": "litevština",
    "lu": "lubština",
    "lv": "lotyština",
    "mg": "malgaština",
    "mh": "maršálština",
    "mi": "maorština",
    "mk": "makedonština",
    "ml": "malajámština",
    "mn": "mongolština",
    "mr": "maráthština",
    "ms": "malajština",
    "mt": "maltština",
    "my": "barmština",
    "na": "nauruština",
    "nb": "bokmål",
    "nd": "severní ndebelština",
    "ne": "nepálština",
    "ng": "ndonga",
    "nl": "nizozemština",
    "nn": "nynorsk",
    "no": "norština",
    "nr": "jižní ndebelština",
    "nv": "navažština",
    "ny": "čičevština",
    "oc": "okcitánština",
    "oj": "odžibvejština",
    "om": "oromština",
    "or": "urijština",
    "os": "osetština",
    "pa": "paňdžábština",
    "pi": "páli",
    "pl": "polština",
    "ps": "paštština",
    "pt": "portugalština",
    "qu": "kečuánština",
    "rm": "rétorománština",
    "rn": "kirundština",
    "ro": "rumunština",
    "ru": "ruština",
    "rw": "rwandština",
    "sa": "sanskrt",
    "sc": "sardština",
    "sd": "sindhština",
    "se": "severní sámština",
    "sg": "sangština",
    "si": "sinhálština",
    "sk": "slovenština",
    "sl": "slovinština",
    "sm": "samojština",
    "sn": "šonština",
    "so": "somálština",
    "sq": "albánština",
    "sr": "srbština",
    "ss": "svazijština",
    "st": "sotština",
    "su": "sundština",
    "sv": "švédština",
    "sw": "svahilština",
    "ta": "tamilština",
    "te": "telugština",
    "tg": "tádžičtina",
    "th": "thajština",
    "ti": "tigriňňa",
    "tk": "turkmenština",
    "tl": "tagalština",
    "tn": "čwanština",
    "to": "tonžština",
    "tr": "turečtina",
    "ts": "tsonga",
    "tt": "tatarština",
    "tw": "ťwiština",
    "ty": "tahitština",
    "ug": "ujgurština",
    "uk": "ukrajinština",
    "ur": "urdština",
    "uz": "uzbečtina",
    "ve": "luvendština",
    "vi": "vietnamština",
    "vo": "volapük",
    "wa": "valonština",
    "wo": "volofština",
    "xh": "xhoština",
    "yi": "jidiš",
    "yo": "jorubština",
    "za": "čuangština",
    "zh": "čínština",
    "zu": "zulština"
  }
}