{
  "locale": "ro",
  "languages": {
    "aa": "afar",
    "ab": "abhază",
    "ae": "avestană",
    "af": "afrikaans",
    "ak": "akan",
    "am": "amharică",
    "an": "aragoneză",
    "ar": "arabă",
    "as": "assameză",
    "av": "avară",
    "ay": "aymara",
    "az": "azeră",
    "ba": "bașkiră",
    "be": "bielorusă",
    "bg": "bulgară",
    "bh": "limbi bihari",
    "bi": "bislama",
    "bm": "bambara",
    "bn": "bengaleză",
    "bo": "tibetană",
    "br": "bretonă",
    "bs": "bosniacă",
    "ca": "catalană",
    "ce": "cecenă",
    "ch": "chamorro",
    "co": "corsicană",
    "cr": "cree",
    "cs": "cehă",
    "cu": "slavona bisericească",
    "cv": "ciuvașă",
    "cy": "galeză",
    "da": "daneză",
    "de": "germană",
    "dv": "maldivian",
    "dz": "dzongkha",
    "ee": "ewe",
    "el": "neogreacă",
    "en": "engleză",
    "eo": "esperanto",
    "es": "spaniolă",
    "et": "estonă",
    "eu": "bască",
    "fa": "persană",
    "ff": "fula",
    "fi": "fFinlandeză",
    "fj": "fijiană",
    "fo": "feroeză",
    "fr": "franceză",
    "fy": "western frisian",
    "ga": "irlandeză",
    "gd": "scoțiană",
    "gl": "galiciană",
    "gn": "guaraní",
    "gu": "gujarati",
    "gv": "manx",
    "ha": "hausa",
    "he": "ebraică",
    "hi": "hindi",
    "ho": "hiri motu",
    "hr": "croată",
    "ht": "creolă haitiană",
    "hu": "maghiară",
    "hy": "armeană",
    "hz": "herero",
    "ia": "interlingua",
    "id": "indoneziană",
    "ie": "interlingue",
    "ig": "igbo",
    "ii": "nuosu",
    "ik": "inupiaq",
    "io": "ido",
    "is": "islandeză",
    "it": "italiană",
    "iu": "inuktitut",
    "ja": "japoneză",
    "jv": "javaneză",
    "ka": "georgiană",
    "kg": "kongo",
    "ki": "kikuyu",
    "kj": "kwanyama",
    "kk": "kazahă",
    "kl": "groenlandeza",
    "km": "khmeră ",
    "kn": "kannada",
    "ko": "coreeană",
    "kr": "kanuri",
    "ks": "kashmiri",
    "ku": "kurdă",
    "kv": "komi",
    "kw": "cornică",
    "ky": "kârgâză",
    "la": "latin",
    "lb": "luxemburgheză",
    "lg": "ganda",
    "li": "limburgheză",
    "ln": "lingala",
    "lo": "laoțiană",
    "lt": "lituaniană",
    "lu": "luba-katanga",
    "lv": "letonă",
    "mg": "malgașă",
    "mh": "marshallese",
    "mi": "maori",
    "mk": "macedoneană",
    "ml": "malayalam",
    "mn": "mongola",
    "mr": "marathi",
    "ms": "malaieză",
    "mt": "malteză",
    "my": "birmană",
    "na": "nauruana",
    "nb": "norvegiana cărturărească",
    "nd": "north ndebele",
    "ne": "nepaleză",
    "ng": "ndonga",
    "nl": "olandeză",
    "nn": "norvegiana nouă",
    "no": "norvegiană",
    "nr": "south ndebele",
    "nv": "navajo",
    "ny": "nyanja",
    "oc": "occitană",
    "oj": "ojibwa",
    "om": "oromo",
    "or": "oriya",
    "os": "osetă",
    "pa": "punjaba ",
    "pi": "pali",
    "pl": "poloneză",
    "ps": "paștu",
    "pt": "portugheză",
    "qu": "quechua",
    "rm": "retoromană",
    "rn": "rundi",
    "ro": "română",
    "ru": "rusă",
    "rw": "Kinyarwanda",
    "sa": "Sanskrit",
    "sc": "sanscrită",
    "sd": "sindhi",
    "se": "northern sami",
    "sg": "sango",
    "si": "singaleză",
    "sk": "slovacă",
    "sl": "slovenă",
    "sm": "samoană",
    "sn": "shona",
    "so": "somaleză",
    "sq": "albaneză",
    "sr": "sârbă",
    "ss": "swati",
    "st": "sotho de sud",
    "su": "sundanese",
    "sv": "suedeză",
    "sw": "swahili",
    "ta": "tamilă",
    "te": "telugu",
    "tg": "tadjică",
    "th": "Thailandeză",
    "ti": "tigrinya",
    "tk": "turkmenă",
    "tl": "tagalog",
    "tn": "tswana",
    "to": "tonga",
    "tr": "turcă",
    "ts": "tsonga",
    "tt": "tătară",
    "tw": "twi",
    "ty": "tahitiană",
    "ug": "uigură",
    "uk": "ucraineană",
    "ur": "urdu",
    "uz": "uzbecă",
    "ve": "venda",
    "vi": "vietnameză",
    "vo": "volapük",
    "wa": "valonă",
    "wo": "wolof",
    "xh": "xhosa",
    "yi": "idiș",
    "yo": "yoruba",
    "za": "zhuang",
    "zh": "chineză",
    "zu": "zulu"
  }
}
