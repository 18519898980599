{
  "locale": "hu",
  "languages": {
    "aa": "afar",
    "ab": "abház",
    "ae": "avesztai",
    "af": "afrikaans",
    "ak": "akan",
    "am": "amhara",
    "an": "aragóniai",
    "ar": "arab",
    "as": "asszámi",
    "av": "avar",
    "ay": "ajmara",
    "az": "azeri",
    "ba": "baskír",
    "be": "belarusz",
    "bg": "bolgár",
    "bh": "maithili",
    "bi": "biszlama",
    "bm": "bambara",
    "bn": "bengáli",
    "bo": "tibeti",
    "br": "breton",
    "bs": "bosnyák",
    "ca": "valenciai",
    "ce": "csecsen",
    "ch": "csamorro",
    "co": "korzikai",
    "cr": "krí",
    "cs": "cseh",
    "cu": "óegyházi szláv",
    "cv": "csuvas",
    "cy": "walesi",
    "da": "dán",
    "de": "német",
    "dv": "maldív",
    "dz": "dzongkha",
    "ee": "eve",
    "el": "görög",
    "en": "angol",
    "eo": "eszperantó",
    "es": "spanyol",
    "et": "észt",
    "eu": "baszk",
    "fa": "perzsa",
    "ff": "ful",
    "fi": "finn",
    "fj": "fidzsi",
    "fo": "feröeri",
    "fr": "francia",
    "fy": "fríz",
    "ga": "ír",
    "gd": "skót gael",
    "gl": "galiciai",
    "gn": "guarani",
    "gu": "gudzsaráti",
    "gv": "manx",
    "ha": "hausza",
    "he": "héber",
    "hi": "hindi",
    "ho": "hiri motu",
    "hr": "horvát",
    "ht": "haiti kreol",
    "hu": "magyar",
    "hy": "örmény",
    "hz": "herero",
    "ia": "interlingva",
    "id": "maláj",
    "ie": "interlingue",
    "ig": "igbó",
    "ii": "nuoszu",
    "ik": "inupiak",
    "io": "ido",
    "is": "izlandi",
    "it": "olasz",
    "iu": "inuktitut",
    "ja": "japán",
    "jv": "jávai",
    "ka": "grúz",
    "kg": "kikongo",
    "ki": "kikuju",
    "kj": "kvanyama",
    "kk": "kazak",
    "kl": "grönlandi",
    "km": "khmer",
    "kn": "kannada",
    "ko": "koreai",
    "kr": "kanuri",
    "ks": "kasmíri",
    "ku": "kurd",
    "kv": "komi",
    "kw": "korni",
    "ky": "kirgiz",
    "la": "latin",
    "lb": "luxemburgi",
    "lg": "luganda",
    "li": "limburgi",
    "ln": "lingala",
    "lo": "lao",
    "lt": "litván",
    "lu": "luba-katanga",
    "lv": "lett",
    "mg": "malgas",
    "mh": "Marshall-szigeteki",
    "mi": "maori",
    "mk": "macedón",
    "ml": "malajálam",
    "mn": "mongol",
    "mr": "maráthi",
    "ms": "maláj",
    "mt": "máltai",
    "my": "burmai",
    "na": "naurui",
    "nb": "norvég",
    "nd": "északi ndebele",
    "ne": "nepáli",
    "ng": "ndonga",
    "nl": "holland",
    "nn": "norvég",
    "no": "nynorsk",
    "nr": "déli ndebele",
    "nv": "navahó",
    "ny": "cseva",
    "oc": "okcitán",
    "oj": "odzsibva",
    "om": "oromó",
    "or": "orija",
    "os": "oszét",
    "pa": "pandzsábi",
    "pi": "páli",
    "pl": "lengyel",
    "ps": "pastu",
    "pt": "portugál",
    "qu": "kecsua",
    "rm": "romans",
    "rn": "rundi",
    "ro": "román",
    "ru": "orosz",
    "rw": "kinyarvanda",
    "sa": "szanszkrit",
    "sc": "szárd",
    "sd": "szindhi",
    "se": "északi számi",
    "sg": "szangó",
    "si": "szingaléz",
    "sk": "szlovák",
    "sl": "szlovén",
    "sm": "szamoai",
    "sn": "sona",
    "so": "szomáli",
    "sq": "albán",
    "sr": "szerb",
    "ss": "szvázi",
    "st": "déli szoto",
    "su": "szundai",
    "sv": "svéd",
    "sw": "szuahéli",
    "ta": "tamil",
    "te": "telugu",
    "tg": "tádzsik",
    "th": "thai",
    "ti": "tigrinya",
    "tk": "türkmén",
    "tl": "tagalog",
    "tn": "csvana",
    "to": "tonga",
    "tr": "török",
    "ts": "conga",
    "tt": "tatár",
    "tw": "tvi",
    "ty": "tahiti",
    "ug": "ujgur",
    "uk": "ukrán",
    "ur": "urdu",
    "uz": "üzbég",
    "ve": "venda",
    "vi": "vietnámi",
    "vo": "volapük",
    "wa": "vallon",
    "wo": "volof",
    "xh": "xhosza",
    "yi": "jiddis",
    "yo": "joruba",
    "za": "csuang",
    "zh": "kínai",
    "zu": "zulu"
  }
}