{
  "locale": "fr",
  "languages": {
    "aa": "Afar",
    "ab": "Abkhaze",
    "ae": "Avestique",
    "af": "Afrikaans",
    "ak": "Akan",
    "am": "Amharique",
    "an": "Aragonais",
    "ar": "Arabe",
    "as": "Assamais",
    "av": "Avar",
    "ay": "Aymara",
    "az": "Azéri",
    "ba": "Bachkir",
    "be": "Biélorusse",
    "bg": "Bulgare",
    "bh": "maïthili",
    "bi": "Bichelamar",
    "bm": "Bambara",
    "bn": "Bengali",
    "bo": "Tibétain",
    "br": "Breton",
    "bs": "Bosnien",
    "ca": "Catalan",
    "ce": "Tchétchène",
    "ch": "Chamorro",
    "co": "Corse",
    "cr": "Cri",
    "cs": "Tchèque",
    "cu": "Vieux-slave",
    "cv": "Tchouvache",
    "cy": "Gallois",
    "da": "Danois",
    "de": "Allemand",
    "dv": "Maldivien",
    "dz": "Dzongkha",
    "ee": "Ewe",
    "el": "Grec moderne",
    "en": "Anglais",
    "eo": "Espéranto",
    "es": "Espagnol",
    "et": "Estonien",
    "eu": "Basque",
    "fa": "Persan",
    "ff": "Peul",
    "fi": "Finnois",
    "fj": "Fidjien",
    "fo": "Féroïen",
    "fr": "Français",
    "fy": "Frison occidental",
    "ga": "Irlandais",
    "gd": "Écossais",
    "gl": "Galicien",
    "gn": "Guarani",
    "gu": "Gujarati",
    "gv": "Mannois",
    "ha": "Haoussa",
    "he": "Hébreu",
    "hi": "Hindi",
    "ho": "Hiri motu",
    "hr": "Croate",
    "ht": "Créole haïtien",
    "hu": "Hongrois",
    "hy": "Arménien",
    "hz": "Héréro",
    "ia": "Interlingua",
    "id": "Indonésien",
    "ie": "Occidental",
    "ig": "Igbo",
    "ii": "Yi",
    "ik": "Inupiak",
    "io": "Ido",
    "is": "Islandais",
    "it": "Italien",
    "iu": "Inuktitut",
    "ja": "Japonais",
    "jv": "Javanais",
    "ka": "Géorgien",
    "kg": "Kikongo",
    "ki": "Kikuyu",
    "kj": "Kuanyama",
    "kk": "Kazakh",
    "kl": "Groenlandais",
    "km": "Khmer",
    "kn": "Kannada",
    "ko": "Coréen",
    "kr": "Kanouri",
    "ks": "Cachemiri",
    "ku": "Kurde",
    "kv": "Komi",
    "kw": "Cornique",
    "ky": "Kirghiz",
    "la": "Latin",
    "lb": "Luxembourgeois",
    "lg": "Luganda",
    "li": "Limbourgeois",
    "ln": "Lingala",
    "lo": "Lao",
    "lt": "Lituanien",
    "lu": "Luba-katanga",
    "lv": "Letton",
    "mg": "Malgache",
    "mh": "Marshallais",
    "mi": "Maori de Nouvelle-Zélande",
    "mk": "Macédonien",
    "ml": "Malayalam",
    "mn": "Mongol",
    "mr": "Marathi",
    "ms": "Malais",
    "mt": "Maltais",
    "my": "Birman",
    "na": "Nauruan",
    "nb": "Bokmål",
    "nd": "Sindebele",
    "ne": "Népalais",
    "ng": "Ndonga",
    "nl": "Néerlandais",
    "nn": "Nynorsk",
    "no": "Norvégien",
    "nr": "Nrebele",
    "nv": "Navajo",
    "ny": "Chichewa",
    "oc": "Occitan",
    "oj": "Ojibwé",
    "om": "Oromo",
    "or": "Oriya",
    "os": "Ossète",
    "pa": "Pendjabi",
    "pi": "Pali",
    "pl": "Polonais",
    "ps": "Pachto",
    "pt": "Portugais",
    "qu": "Quechua",
    "rm": "Romanche",
    "rn": "Kirundi",
    "ro": "Roumain",
    "ru": "Russe",
    "rw": "Kinyarwanda",
    "sa": "Sanskrit",
    "sc": "Sarde",
    "sd": "Sindhi",
    "se": "Same du Nord",
    "sg": "Sango",
    "si": "Cingalais",
    "sk": "Slovaque",
    "sl": "Slovène",
    "sm": "Samoan",
    "sn": "Shona",
    "so": "Somali",
    "sq": "Albanais",
    "sr": "Serbe",
    "ss": "Swati",
    "st": "Sotho du Sud",
    "su": "Soundanais",
    "sv": "Suédois",
    "sw": "Swahili",
    "ta": "Tamoul",
    "te": "Télougou",
    "tg": "Tadjik",
    "th": "Thaï",
    "ti": "Tigrigna",
    "tk": "Turkmène",
    "tl": "Tagalog",
    "tn": "Tswana",
    "to": "Tongien",
    "tr": "Turc",
    "ts": "Tsonga",
    "tt": "Tatar",
    "tw": "Twi",
    "ty": "Tahitien",
    "ug": "Ouïghour",
    "uk": "Ukrainien",
    "ur": "Ourdou",
    "uz": "Ouzbek",
    "ve": "Venda",
    "vi": "Vietnamien",
    "vo": "Volapük",
    "wa": "Wallon",
    "wo": "Wolof",
    "xh": "Xhosa",
    "yi": "Yiddish",
    "yo": "Yoruba",
    "za": "Zhuang",
    "zh": "Chinois",
    "zu": "Zoulou"
  }
}