{
  "locale": "pt",
  "languages": {
    "aa": "afar",
    "ab": "abcázio",
    "ae": "avéstico",
    "af": "africânder",
    "ak": "akan",
    "am": "amárico",
    "an": "aragonês",
    "ar": "árabe",
    "as": "assamês",
    "av": "avárico",
    "ay": "aimará",
    "az": "azerbaijano",
    "ba": "basquir",
    "be": "bielorrusso",
    "bg": "búlgaro",
    "bh": "línguas biaris",
    "bi": "bislamá",
    "bm": "bâmbara",
    "bn": "bengali",
    "bo": "tibetano",
    "br": "bretão",
    "bs": "bósnio",
    "ca": "catalão",
    "ce": "checheno",
    "ch": "chamorro",
    "co": "corso",
    "cr": "cree",
    "cs": "checo",
    "cu": "eslavo eclesiástico",
    "cv": "chuvache",
    "cy": "galês",
    "da": "dinamarquês",
    "de": "alemão",
    "dv": "maldívio",
    "dz": "butanês",
    "ee": "ewe",
    "el": "grego moderno",
    "en": "inglês",
    "eo": "esperanto",
    "es": "espanhol",
    "et": "estoniano",
    "eu": "basco",
    "fa": "persa",
    "ff": "fula",
    "fi": "filandês",
    "fj": "fidjiano",
    "fo": "feroês",
    "fr": "francês",
    "fy": "frisão ocidental",
    "ga": "irlandês",
    "gd": "gaélico escocês",
    "gl": "galego",
    "gn": "guarani",
    "gu": "gujarati",
    "gv": "manês",
    "ha": "haussá",
    "he": "hebraico",
    "hi": "hindi",
    "ho": "hiri motu",
    "hr": "croata",
    "ht": "crioulo haitiano",
    "hu": "húngaro",
    "hy": "arménio",
    "hz": "heréro",
    "ia": "interlíngua",
    "id": "indonésio",
    "ie": "interlíngua",
    "ig": "ibo",
    "ii": "yi de Sichuan",
    "ik": "inupiaque",
    "io": "ido",
    "is": "islandês",
    "it": "italiano",
    "iu": "inuctitut",
    "ja": "japônes",
    "jv": "javanês",
    "ka": "georgiano",
    "kg": "quicongo",
    "ki": "quicuio",
    "kj": "cuanhama",
    "kk": "cazaque",
    "kl": "groenlandês",
    "km": "cambojano",
    "kn": "canarês",
    "ko": "coreano",
    "kr": "canúri",
    "ks": "caxemir",
    "ku": "curdo",
    "kv": "komi",
    "kw": "córnico",
    "ky": "quirguiz",
    "la": "latim",
    "lb": "luxemburguês",
    "lg": "luganda",
    "li": "limburguês",
    "ln": "lingala",
    "lo": "laociano",
    "lt": "lituano",
    "lu": "luba-katanga",
    "lv": "letão",
    "mg": "malgaxe",
    "mh": "marchalês",
    "mi": "maori",
    "mk": "macedónio",
    "ml": "malaiala",
    "mn": "mongol",
    "mr": "marata",
    "ms": "malaio",
    "mt": "maltês",
    "my": "birmanês",
    "na": "nauruano",
    "nb": "bokmål norueguês",
    "nd": "ndebele do norte",
    "ne": "nepalês",
    "ng": "ndonga",
    "nl": "holandês",
    "nn": "novo norueguês",
    "no": "norueguês",
    "nr": "ndebele do sul",
    "nv": "navajo",
    "ny": "nianja",
    "oc": "occitano",
    "oj": "chippewa",
    "om": "oromo",
    "or": "oriá",
    "os": "osseto",
    "pa": "punjabi",
    "pi": "páli",
    "pl": "polaco",
    "ps": "afegão",
    "pt": "português",
    "qu": "quéchua",
    "rm": "romanche",
    "rn": "rundi",
    "ro": "romeno",
    "ru": "russo",
    "rw": "ruanda",
    "sa": "sânscrito",
    "sc": "sardenho",
    "sd": "sindi",
    "se": "sami do norte",
    "sg": "sango",
    "si": "cingalês",
    "sk": "eslovaco",
    "sl": "esloveno",
    "sm": "samoano",
    "sn": "xona",
    "so": "somali",
    "sq": "albanês",
    "sr": "sérvio",
    "ss": "suázi",
    "st": "soto do sul",
    "su": "sundanês",
    "sv": "sueco",
    "sw": "suaíli",
    "ta": "tâmil",
    "te": "telugu",
    "tg": "tajique",
    "th": "tailandês",
    "ti": "tigrínia",
    "tk": "turcomano",
    "tl": "filipino",
    "tn": "tsuana",
    "to": "tonganês",
    "tr": "turco",
    "ts": "tsonga",
    "tt": "tártaro",
    "tw": "axante",
    "ty": "taitiano",
    "ug": "uigure",
    "uk": "ucraniano",
    "ur": "urdu",
    "uz": "uzbeque",
    "ve": "venda",
    "vi": "vietnamita",
    "vo": "volapuque",
    "wa": "valão",
    "wo": "uolofe",
    "xh": "xossa",
    "yi": "ídiche",
    "yo": "ioruba",
    "za": "zhuang",
    "zh": "chinês",
    "zu": "zulu"
  }
}
