{
  "locale": "lv",
  "languages": {
    "aa": "Afāru valoda",
    "ab": "Abhāzu valoda",
    "ae": "Avesta (valoda)|Avesta",
    "af": "afrikāneru valoda",
    "ak": "Akanu valodas|Akanu valoda",
    "am": "Amharu valoda",
    "an": "Aragoniešu valoda",
    "ar": "Arābu valoda",
    "as": "Asamiešu valoda",
    "av": "Avāru valoda",
    "ay": "Aimaru valoda",
    "az": "Azerbaidžāņu valoda",
    "ba": "Baškīru valoda",
    "be": "Baltkrievu valoda",
    "bg": "Bulgāru valoda",
    "bh": "maithilī",
    "bi": "Bislama",
    "bm": "Bambaru valoda",
    "bn": "Bengāļu valoda",
    "bo": "Tibetiešu valoda",
    "br": "Bretoņu valoda",
    "bs": "Bosniešu valoda",
    "ca": "Katalāņu valoda",
    "ce": "Čečenu valoda",
    "ch": "Čamorru valoda",
    "co": "Korsikāņu valoda",
    "cr": "Krī valoda",
    "cs": "Čehu valoda",
    "cu": "Baznīcslāvu valoda",
    "cv": "Čuvašu valoda",
    "cy": "Velsiešu valoda",
    "da": "Dāņu valoda",
    "de": "Vācu valoda",
    "dv": "divehi",
    "dz": "dzongka",
    "ee": "Evu valoda",
    "el": "Grieķu valoda",
    "en": "Angļu valoda",
    "eo": "Esperanto",
    "es": "Spāņu valoda",
    "et": "Igauņu valoda",
    "eu": "Basku valoda",
    "fa": "fārsī",
    "ff": "fulfulde",
    "fi": "Somu valoda",
    "fj": "Fidžiešu valoda",
    "fo": "fēriešu valoda",
    "fr": "Franču valoda",
    "fy": "Rietumfrīzu valoda",
    "ga": "Īru valoda",
    "gd": "Skotu gēlu valoda",
    "gl": "Galisiešu valoda",
    "gn": "Gvaranu valoda",
    "gu": "Gudžaratu valoda",
    "gv": "Meniešu valoda",
    "ha": "Hausu valoda",
    "he": "Ivrits",
    "hi": "Hindi",
    "ho": "hirimotu",
    "hr": "Horvātu valoda",
    "ht": "Haitiešu valoda",
    "hu": "Ungāru valoda",
    "hy": "Armēņu valoda",
    "hz": "Hereru valoda",
    "ia": "Interlingva",
    "id": "Indonēziešu valoda",
    "ie": "Interlingve",
    "ig": "Igbo valoda",
    "ii": "ji valoda",
    "ik": "Inupiaku valoda",
    "io": "Ido",
    "is": "Islandiešu valoda",
    "it": "Itāļu valoda",
    "iu": "inuktituta",
    "ja": "Japāņu valoda",
    "jv": "Javiešu valoda",
    "ka": "Gruzīnu valoda",
    "kg": "kikongo",
    "ki": "gekoju valoda",
    "kj": "kvaņama",
    "kk": "Kazahu valoda",
    "kl": "kalāhlisuta",
    "km": "Khmeru valoda",
    "kn": "kannadigu valoda",
    "ko": "Korejiešu valoda",
    "kr": "Kanuru valoda",
    "ks": "kašmiru valoda",
    "ku": "Kurdu valoda",
    "kv": "komiešu valoda|komiešu valodu",
    "kw": "Korniešu valoda",
    "ky": "Kirgīzu valoda",
    "la": "Latīņu valoda",
    "lb": "Luksemburgiešu valoda",
    "lg": "luganda",
    "li": "Limburgiešu valoda",
    "ln": "Lingala",
    "lo": "Laosiešu valoda",
    "lt": "Lietuviešu valoda",
    "lu": "kiluba",
    "lv": "Latviešu valoda",
    "mg": "malgašu valoda",
    "mh": "Māršaliešu valoda",
    "mi": "Maoru valoda",
    "mk": "Maķedoniešu valoda",
    "ml": "malajalāma",
    "mn": "Mongoļu valoda",
    "mr": "Marathu valoda",
    "ms": "Malajiešu valoda",
    "mt": "Maltiešu valoda",
    "my": "Birmiešu valoda",
    "na": "Nauruiešu valoda",
    "nb": "Norvēģu bukmols",
    "nd": "Ziemeļndebelu valoda",
    "ne": "Nepāliešu valoda",
    "ng": "ošivambo",
    "nl": "nīderlandiešu valoda",
    "nn": "Jaunnorvēģu valoda",
    "no": "Jaunnorvēģu valoda|jaunnorvēģu valodu",
    "nr": "Dienvidndebelu valoda",
    "nv": "Navahu valoda",
    "ny": "čiņandža",
    "oc": "Oksitāņu valoda",
    "oj": "Odžibvu valoda",
    "om": "Oromu valoda",
    "or": "Oriju valoda",
    "os": "Osetīnu valoda",
    "pa": "Pandžabu valoda",
    "pi": "Pāli valoda",
    "pl": "Poļu valoda",
    "ps": "puštunu valoda",
    "pt": "Portugāļu valoda",
    "qu": "Kečvu valoda",
    "rm": "ladīnu valoda|ladīnu valodu",
    "rn": "kirundi",
    "ro": "Rumāņu valoda",
    "ru": "Krievu valoda",
    "rw": "Kiņaruanda",
    "sa": "Sanskrits",
    "sc": "sardu valoda",
    "sd": "Sindhu valoda",
    "se": "Ziemeļsāmu valoda",
    "sg": "sango",
    "si": "Singāļu valoda",
    "sk": "Slovāku valoda",
    "sl": "Slovēņu valoda",
    "sm": "Samoāņu valoda",
    "sn": "Šonu valoda",
    "so": "Somāļu valoda",
    "sq": "Albāņu valoda",
    "sr": "Serbu valoda",
    "ss": "sisvati",
    "st": "sesoto",
    "su": "sundu valoda",
    "sv": "Zviedru valoda",
    "sw": "Svahili",
    "ta": "Tamilu valoda",
    "te": "Telugu valoda",
    "tg": "Tadžiku valoda",
    "th": "Taju valoda",
    "ti": "tigriņu valoda",
    "tk": "Turkmēņu valoda",
    "tl": "tagalogu valoda",
    "tn": "secvana",
    "to": "Tongiešu valoda",
    "tr": "Turku valoda",
    "ts": "šiconga",
    "tt": "Tatāru valoda",
    "tw": "Tvī valoda",
    "ty": "Taitiešu valoda",
    "ug": "Uiguru valoda",
    "uk": "Ukraiņu valoda",
    "ur": "Urdu",
    "uz": "Uzbeku valoda",
    "ve": "čivenda",
    "vi": "Vjetnamiešu valoda",
    "vo": "Volapiks",
    "wa": "Valoņu valoda",
    "wo": "Volofu valoda",
    "xh": "isiķhosa",
    "yi": "Jidišs",
    "yo": "Jorubu valoda",
    "za": "Džuanu valoda",
    "zh": "Ķīniešu valoda",
    "zu": "isizulu"
  }
}