{
  "locale": "zh",
  "languages": {
    "aa": "阿法尔语",
    "ab": "阿布哈兹语",
    "ae": "阿维斯陀语",
    "af": "南非语",
    "ak": "阿坎语",
    "am": "阿姆哈拉语",
    "an": "阿拉贡语",
    "ar": "阿拉伯语",
    "as": "阿萨姆语",
    "av": "阿瓦尔语",
    "ay": "艾马拉语",
    "az": "阿塞拜疆语",
    "ba": "巴什基尔语",
    "be": "白俄罗斯语",
    "bg": "保加利亚语",
    "bh": "比哈尔语",
    "bi": "比斯拉马语",
    "bm": "班巴拉语",
    "bn": "孟加拉语",
    "bo": "标准藏语",
    "br": "布列塔尼语",
    "bs": "波斯尼亚语",
    "ca": "加泰罗尼亚语",
    "ce": "车臣语",
    "ch": "查莫罗语",
    "co": "柯西嘉语",
    "cr": "克里语",
    "cs": "捷克语",
    "cu": "古教会斯拉夫语",
    "cv": "楚瓦什语",
    "cy": "威尔士语",
    "da": "丹麦语",
    "de": "德语",
    "dv": "迪维西语",
    "dz": "宗喀语",
    "ee": "埃维语",
    "el": "希腊语",
    "en": "英语",
    "eo": "世界语",
    "es": "西班牙语",
    "et": "爱沙尼亚语",
    "eu": "巴斯克语",
    "fa": "波斯语",
    "ff": "富拉语",
    "fi": "芬兰语",
    "fj": "斐济语",
    "fo": "法罗语",
    "fr": "法语",
    "fy": "西弗里斯兰语",
    "ga": "爱尔兰语",
    "gd": "苏格兰盖尔语",
    "gl": "加利西亚语",
    "gn": "瓜拉尼语",
    "gu": "古吉拉特语",
    "gv": "马恩语",
    "ha": "豪萨语",
    "he": "希伯来语",
    "hi": "印地语",
    "ho": "希里莫图语",
    "hr": "克罗地亚语",
    "ht": "海地语",
    "hu": "匈牙利语",
    "hy": "亚美尼亚语",
    "hz": "赫雷罗语",
    "ia": "国际语",
    "id": "印度尼西亚语",
    "ie": "西方国际语",
    "ig": "伊博语",
    "ii": "彝语北部方言",
    "ik": "伊努皮克语",
    "io": "伊多语",
    "is": "冰岛语",
    "it": "意大利语",
    "iu": "因纽特语",
    "ja": "日语",
    "jv": "爪哇语",
    "ka": "格鲁吉亚语",
    "kg": "刚果语",
    "ki": "基库尤语",
    "kj": "库瓦亚马语",
    "kk": "哈萨克语",
    "kl": "格陵兰语",
    "km": "高棉语",
    "kn": "坎纳达语",
    "ko": "韩语",
    "kr": "卡努里语",
    "ks": "克什米尔语",
    "ku": "库尔德语",
    "kv": "科米语",
    "kw": "康沃尔语",
    "ky": "柯尔克孜语",
    "la": "拉丁语",
    "lb": "卢森堡语",
    "lg": "干达语",
    "li": "林堡语",
    "ln": "林加拉语",
    "lo": "老挝语",
    "lt": "立陶宛语",
    "lu": "卢巴卡丹加语",
    "lv": "拉脱维亚语",
    "mg": "马达加斯加语",
    "mh": "马绍尔语",
    "mi": "毛利语",
    "mk": "马其顿语",
    "ml": "马拉雅拉姆语",
    "mn": "蒙古语",
    "mr": "马拉地语",
    "ms": "马来语",
    "mt": "马耳他语",
    "my": "缅甸语",
    "na": "瑙鲁语",
    "nb": "书面挪威语",
    "nd": "北恩德贝莱语",
    "ne": "尼泊尔语",
    "ng": "恩敦加语",
    "nl": "荷兰语",
    "nn": "挪威尼诺斯克语",
    "no": "挪威语",
    "nr": "南恩德贝莱语",
    "nv": "纳瓦霍语",
    "ny": "齐切瓦语",
    "oc": "奥克语",
    "oj": "奥杰布瓦语",
    "om": "奥罗莫语",
    "or": "奥里雅语",
    "os": "奥塞梯语",
    "pa": "旁遮普语",
    "pi": "巴利语",
    "pl": "波兰语",
    "ps": "普什图语",
    "pt": "葡萄牙语",
    "qu": "盖丘亚语",
    "rm": "罗曼什语",
    "rn": "基隆迪语",
    "ro": "罗马尼亚语",
    "ru": "俄语",
    "rw": "卢旺达语",
    "sa": "梵语",
    "sc": "撒丁语",
    "sd": "信德语",
    "se": "北萨米语",
    "sg": "桑戈语",
    "si": "僧伽罗语",
    "sk": "斯洛伐克语",
    "sl": "斯洛文尼亚人",
    "sm": "萨摩亚语",
    "sn": "修纳语",
    "so": "索马里语",
    "sq": "阿尔巴尼亚语",
    "sr": "塞尔维亚语",
    "ss": "斯威士语",
    "st": "南索托语",
    "su": "巽他语",
    "sv": "瑞典语",
    "sw": "斯瓦希里语",
    "ta": "泰米尔语",
    "te": "泰卢固语",
    "tg": "塔吉克语",
    "th": "泰语",
    "ti": "提格里尼亚语",
    "tk": "土库曼语",
    "tl": "塔加拉族语",
    "tn": "茨瓦纳语",
    "to": "汤加语",
    "tr": "土耳其语",
    "ts": "聪加语",
    "tt": "鞑靼语",
    "tw": "契维语",
    "ty": "塔希提语",
    "ug": "维吾尔语",
    "uk": "乌克兰语",
    "ur": "乌尔都语",
    "uz": "乌兹别克语",
    "ve": "文达语",
    "vi": "越南语",
    "vo": "沃拉普克语",
    "wa": "瓦隆语",
    "wo": "沃洛夫语",
    "xh": "科萨语",
    "yi": "意第绪语",
    "yo": "约鲁巴语",
    "za": "壮语",
    "zh": "汉语",
    "zu": "祖鲁语"
  }
}
