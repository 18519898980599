{
  "locale": "uk",
  "languages": {
    "aa": "афарська",
    "ab": "абхазька",
    "ae": "авестійська",
    "af": "африкаанс",
    "ak": "акан (мова)",
    "am": "амхара",
    "an": "арагонська",
    "ar": "арабська",
    "as": "ассамська",
    "av": "аварська",
    "ay": "аймара",
    "az": "азербайджанська",
    "ba": "башкирська",
    "be": "білоруська",
    "bg": "болгарська",
    "bh": "біхарі",
    "bi": "біслама",
    "bm": "бамбара",
    "bn": "бенгальська",
    "bo": "тибетська",
    "br": "бретонська",
    "bs": "боснійська",
    "ca": "каталонська",
    "ce": "чеченська",
    "ch": "чаморро",
    "co": "корсиканська",
    "cr": "мова крі",
    "cs": "чеська",
    "cu": "церковнослов’янська",
    "cv": "чуваська",
    "cy": "валлійська",
    "da": "данська",
    "de": "німецька",
    "dv": "дівехі",
    "dz": "дзонґ-ке",
    "ee": "еве",
    "el": "грецька",
    "en": "англійська",
    "eo": "есперанто",
    "es": "іспанська",
    "et": "естонська",
    "eu": "баскська",
    "fa": "перська",
    "ff": "фула",
    "fi": "фінська",
    "fj": "фіджі",
    "fo": "фарерська",
    "fr": "французька",
    "fy": "західно-фризька",
    "ga": "ірландська",
    "gd": "шотландська гельська",
    "gl": "галісійська",
    "gn": "гуарані",
    "gu": "гуджараті",
    "gv": "менська",
    "ha": "хауса",
    "he": "іврит",
    "hi": "гінді",
    "ho": "гірі-моту",
    "hr": "хорватська",
    "ht": "гаїтянська",
    "hu": "угорська",
    "hy": "вірменська",
    "hz": "гереро",
    "ia": "інтерлінгва",
    "id": "індонезійська",
    "ie": "окциденталь",
    "ig": "ігбо",
    "ii": "носу",
    "ik": "інупіак (мова)",
    "io": "ідо",
    "is": "ісландська",
    "it": "італійська",
    "iu": "інуктітут",
    "ja": "японська",
    "jv": "яванська",
    "ka": "грузинська",
    "kg": "конголезька",
    "ki": "кікуйю",
    "kj": "кунама",
    "kk": "казахська",
    "kl": "гренландська",
    "km": "кхмерська",
    "kn": "каннада",
    "ko": "корейська",
    "kr": "канурі",
    "ks": "кашмір",
    "ku": "курдська",
    "kv": "комі",
    "kw": "корнська",
    "ky": "киргизька",
    "la": "латинська",
    "lb": "люксембурзька",
    "lg": "луганда",
    "li": "лімбурзька",
    "ln": "лінґала",
    "lo": "лаоська",
    "lt": "литовська",
    "lu": "луба-катанга",
    "lv": "латиська",
    "mg": "малагасійська",
    "mh": "маршальська",
    "mi": "маорі",
    "mk": "македонська",
    "ml": "малаялам",
    "mn": "монгольська",
    "mr": "маратхі",
    "ms": "малайська",
    "mt": "мальтійська",
    "my": "бірманська",
    "na": "науру",
    "nb": "букмол",
    "nd": "північна ндебеле",
    "ne": "непальська",
    "ng": "ндонга",
    "nl": "нідерландська",
    "nn": "нюношк",
    "no": "норвезька",
    "nr": "південна ндебеле",
    "nv": "навахо",
    "ny": "ньянджа",
    "oc": "окситанська",
    "oj": "оджибве",
    "om": "орома",
    "or": "орія",
    "os": "осетинська",
    "pa": "панджабі",
    "pi": "палі",
    "pl": "польська",
    "ps": "пушту",
    "pt": "португальська",
    "qu": "кечуа",
    "rm": "ретороманська",
    "rn": "кірундійська",
    "ro": "румунська",
    "ru": "російська",
    "rw": "кінаруанда",
    "sa": "санскрит",
    "sc": "сардинська",
    "sd": "сіндхі",
    "se": "північно-саамська",
    "sg": "санго",
    "si": "сингальська",
    "sk": "словацька",
    "sl": "словенська",
    "sm": "самоанська",
    "sn": "шона",
    "so": "сомалі",
    "sq": "албанська",
    "sr": "сербська",
    "ss": "свазі",
    "st": "сесото",
    "su": "сунданська",
    "sv": "шведська",
    "sw": "свахілі",
    "ta": "тамільська",
    "te": "телугу",
    "tg": "таджицька",
    "th": "тайська",
    "ti": "тигрінья",
    "tk": "туркменська",
    "tl": "тагалог",
    "tn": "сетсвана",
    "to": "тонганська",
    "tr": "турецька",
    "ts": "тсонґа",
    "tt": "татарська",
    "tw": "чві",
    "ty": "таїтянська",
    "ug": "уйгурська",
    "uk": "українська",
    "ur": "урду",
    "uz": "узбецька",
    "ve": "венда",
    "vi": "в'єтнамська",
    "vo": "волапюк",
    "wa": "валлонська",
    "wo": "волоф",
    "xh": "ісікхоса",
    "yi": "їдиш",
    "yo": "йоруба",
    "za": "чжуан",
    "zh": "китайська",
    "zu": "зулу"
  }
}
