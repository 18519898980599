{
  "locale": "ar",
  "languages": {
    "aa": "الأفارية",
    "ab": "الأبخازية",
    "ae": "الأفستية",
    "af": "الأفريقانية",
    "ak": "الأكانية",
    "am": "الأمهرية",
    "an": "الأراغونية",
    "ar": "العربية",
    "as": "الأسامية",
    "av": "الأوارية",
    "ay": "الأيمارا",
    "az": "الأذربيجانية",
    "ba": "الباشكيرية",
    "be": "البيلاروسية",
    "bg": "البلغارية",
    "bi": "البيسلامية",
    "bm": "البامبارا",
    "bn": "البنغالية",
    "bo": "التبتية",
    "br": "البريتونية",
    "bs": "البوسنية",
    "ca": "الكتالانية",
    "ce": "الشيشانية",
    "ch": "التشامورو",
    "co": "الكورسيكية",
    "cr": "الكرى",
    "cs": "التشيكية",
    "cu": "سلافية كنسية",
    "cv": "التشوفاشي",
    "cy": "الولزية",
    "da": "الدانماركية",
    "de": "الألمانية",
    "dv": "المالديفية",
    "dz": "الزونخاية",
    "ee": "الإيوي",
    "el": "اليونانية",
    "en": "الإنجليزية",
    "eo": "الإسبرانتو",
    "es": "الإسبانية",
    "et": "الإستونية",
    "eu": "لغة الباسك",
    "fa": "الفارسية",
    "ff": "الفلة",
    "fi": "الفنلندية",
    "fj": "الفيجية",
    "fo": "الفارويز",
    "fr": "الفرنسية",
    "fy": "الفريزيان",
    "ga": "الأيرلندية",
    "gd": "الغيلية الأسكتلندية",
    "gl": "الجاليكية",
    "gn": "الجواراني",
    "gu": "الغوجاراتية",
    "gv": "المنكية",
    "ha": "الهوسا",
    "he": "العبرية",
    "hi": "الهندية",
    "ho": "الهيري موتو",
    "hr": "الكرواتية",
    "ht": "الهايتية",
    "hu": "الهنغارية",
    "hy": "الأرمينية",
    "hz": "الهيريرو",
    "ia": "اللّغة الوسيطة",
    "id": "الإندونيسية",
    "ie": "الإنترلينج",
    "ig": "الإيجبو",
    "ii": "السيتشيون يي",
    "ik": "الإينبياك",
    "io": "الإيدو",
    "is": "الأيسلاندية",
    "it": "الإيطالية",
    "iu": "الإينكتيتت",
    "ja": "اليابانية",
    "jv": "الجاوية",
    "ka": "الجورجية",
    "kg": "الكونغو",
    "ki": "الكيكيو",
    "kj": "الكيونياما",
    "kk": "الكازاخستانية",
    "kl": "الكالاليست",
    "km": "الخميرية",
    "kn": "الكانادا",
    "ko": "الكورية",
    "kr": "الكانيوري",
    "ks": "الكشميرية",
    "ku": "الكردية",
    "kv": "الكومي",
    "kw": "الكورنية",
    "ky": "القرغيزية",
    "la": "اللاتينية",
    "lb": "اللوكسمبرجية",
    "lg": "الجاندا",
    "li": "الليمبرجيشية",
    "ln": "اللينجالا",
    "lo": "اللاوية",
    "lt": "اللتوانية",
    "lu": "اللبا-كاتانجا",
    "lv": "اللاتفية",
    "mg": "المالاجاشية",
    "mh": "المارشالية",
    "mi": "الماورية",
    "mk": "المقدونية",
    "ml": "الماليالام",
    "mn": "المنغولية",
    "mr": "الماراثي",
    "ms": "لغة الملايو",
    "mt": "المالطية",
    "my": "البورمية",
    "na": "النورو",
    "nb": "البوكمالية النرويجية",
    "nd": "النديبيل الشمالي",
    "ne": "النيبالية",
    "ng": "الندونجا",
    "nl": "الهولندية",
    "nn": "النينورسك النرويجي",
    "no": "النرويجية",
    "nr": "النديبيل الجنوبي",
    "nv": "النافاجو",
    "ny": "النيانجا",
    "oc": "الأوكيتانية",
    "oj": "الأوجيبوا",
    "om": "الأورومو",
    "or": "الأورييا",
    "os": "الأوسيتيك",
    "pa": "البنجابية",
    "pi": "البالية",
    "pl": "البولندية",
    "ps": "البشتونية",
    "pt": "البرتغالية",
    "qu": "الكويتشوا",
    "rm": "الرومانشية",
    "rn": "الرندي",
    "ro": "الرومانية",
    "ru": "الروسية",
    "rw": "الكينيارواندا",
    "sa": "السنسكريتية",
    "sc": "السردينية",
    "sd": "السندية",
    "se": "السامي الشمالي",
    "sg": "السانجو",
    "si": "السنهالية",
    "sk": "السلوفاكية",
    "sl": "السلوفانية",
    "sm": "الساموائية",
    "sn": "الشونا",
    "so": "الصومالية",
    "sq": "الألبانية",
    "sr": "الصربية",
    "ss": "السواتي",
    "st": "السوتو الجنوبية",
    "su": "السوندانية",
    "sv": "السويدية",
    "sw": "السواحلية",
    "ta": "التاميلية",
    "te": "التيلجو",
    "tg": "الطاجيكية",
    "th": "التايلاندية",
    "ti": "التغرينية",
    "tk": "التركمانية",
    "tl": "التاغالوغية",
    "tn": "التسوانية",
    "to": "التونغية",
    "tr": "التركية",
    "ts": "السونجا",
    "tt": "التتارية",
    "tw": "التوي",
    "ty": "التاهيتية",
    "ug": "الأغورية",
    "uk": "الأوكرانية",
    "ur": "الأردية",
    "uz": "الأوزبكية",
    "ve": "الفيندا",
    "vi": "الفيتنامية",
    "vo": "لغة الفولابوك",
    "wa": "الولونية",
    "wo": "الولوف",
    "xh": "الخوسا",
    "yi": "اليديشية",
    "yo": "اليوروبية",
    "za": "الزهيونج",
    "zh": "الصينية",
    "zu": "الزولو"
  }
}
