{
  "locale": "it",
  "languages": {
    "aa": "afar",
    "ab": "abcaso",
    "ae": "avestan",
    "af": "afrikaans",
    "ak": "akan",
    "am": "amarico",
    "an": "aragonese",
    "ar": "arabo",
    "as": "assamese",
    "av": "avaro",
    "ay": "aymara",
    "az": "azerbaigiano",
    "ba": "baschiro",
    "be": "bielorusso",
    "bg": "bulgaro",
    "bh": "maïthili",
    "bi": "bislama",
    "bm": "bambara",
    "bn": "bengalese",
    "bo": "tibetano",
    "br": "bretone",
    "bs": "bosniaco",
    "ca": "catalano",
    "ce": "ceceno",
    "ch": "chamorro",
    "co": "corso",
    "cr": "cree",
    "cs": "ceco",
    "cu": "slavo della Chiesa",
    "cv": "chuvash",
    "cy": "gallese",
    "da": "danese",
    "de": "tedesco",
    "dv": "divehi",
    "dz": "dzongkha",
    "ee": "ewe",
    "el": "greco",
    "en": "inglese",
    "eo": "esperanto",
    "es": "spagnolo",
    "et": "estone",
    "eu": "basco",
    "fa": "persiano",
    "ff": "fulah",
    "fi": "finlandese",
    "fj": "figiano",
    "fo": "faroese",
    "fr": "francese",
    "fy": "frisone occidentale",
    "ga": "irlandese",
    "gd": "gaelico scozzese",
    "gl": "galiziano",
    "gn": "guaraní",
    "gu": "gujarati",
    "gv": "manx",
    "ha": "hausa",
    "he": "ebraico",
    "hi": "hindi",
    "ho": "hiri motu",
    "hr": "croato",
    "ht": "haitiano",
    "hu": "ungherese",
    "hy": "armeno",
    "hz": "herero",
    "ia": "interlingua",
    "id": "indonesiano",
    "ie": "interlingue",
    "ig": "igbo",
    "ii": "sichuan yi",
    "ik": "inupiak",
    "io": "ido",
    "is": "islandese",
    "it": "italiano",
    "iu": "inuktitut",
    "ja": "giapponese",
    "jv": "giavanese",
    "ka": "georgiano",
    "kg": "kongo",
    "ki": "kikuyu",
    "kj": "kuanyama",
    "kk": "kazako",
    "kl": "kalaallisut",
    "km": "khmer",
    "kn": "kannada",
    "ko": "coreano",
    "kr": "kanuri",
    "ks": "kashmiri",
    "ku": "curdo",
    "kv": "komi",
    "kw": "cornico",
    "ky": "chirghiso",
    "la": "latino",
    "lb": "lussemburghese",
    "lg": "ganda",
    "li": "limburgese",
    "ln": "lingala",
    "lo": "lao",
    "lt": "lituano",
    "lu": "luba-katanga",
    "lv": "lettone",
    "mg": "malgascio",
    "mh": "marshallese",
    "mi": "maori",
    "mk": "macedone",
    "ml": "malayalam",
    "mn": "mongolo",
    "mr": "marathi",
    "ms": "malese",
    "mt": "maltese",
    "my": "birmano",
    "na": "nauru",
    "nb": "norvegese bokmål",
    "nd": "ndebele del nord",
    "ne": "nepalese",
    "ng": "ndonga",
    "nl": "olandese",
    "nn": "norvegese nynorsk",
    "no": "norvegese",
    "nr": "ndebele del sud",
    "nv": "navajo",
    "ny": "nyanja",
    "oc": "occitano",
    "oj": "ojibwa",
    "om": "oromo",
    "or": "oriya",
    "os": "ossetico",
    "pa": "punjabi",
    "pi": "pali",
    "pl": "polacco",
    "ps": "pashto",
    "pt": "portoghese",
    "qu": "quechua",
    "rm": "romancio",
    "rn": "rundi",
    "ro": "rumeno",
    "ru": "russo",
    "rw": "kinyarwanda",
    "sa": "sanscrito",
    "sc": "sardo",
    "sd": "sindhi",
    "se": "sami del nord",
    "sg": "sango",
    "si": "singalese",
    "sk": "slovacco",
    "sl": "sloveno",
    "sm": "samoano",
    "sn": "shona",
    "so": "somalo",
    "sq": "albanese",
    "sr": "serbo",
    "ss": "swati",
    "st": "sotho del sud",
    "su": "sundanese",
    "sv": "svedese",
    "sw": "swahili",
    "ta": "tamil",
    "te": "telugu",
    "tg": "tagico",
    "th": "thai",
    "ti": "tigrino",
    "tk": "turcomanno",
    "tl": "tagalog",
    "tn": "tswana",
    "to": "tongano",
    "tr": "turco",
    "ts": "tsonga",
    "tt": "tataro",
    "tw": "ci",
    "ty": "taitiano",
    "ug": "uiguro",
    "uk": "ucraino",
    "ur": "urdu",
    "uz": "usbeco",
    "ve": "venda",
    "vi": "vietnamita",
    "vo": "volapük",
    "wa": "vallone",
    "wo": "wolof",
    "xh": "xhosa",
    "yi": "yiddish",
    "yo": "yoruba",
    "za": "zhuang",
    "zh": "cinese",
    "zu": "zulu"
  }
}
