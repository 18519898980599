{
  "locale": "ms",
  "languages": {
    "aa": "Afar",
    "ab": "Abkhazia",
    "ae": "Avestan",
    "af": "Afrikaans",
    "ak": "Akan",
    "am": "Amharic",
    "an": "Aragonese",
    "ar": "Arab",
    "as": "Assam",
    "av": "Avaric",
    "ay": "Aymara",
    "az": "Azerbaijan",
    "ba": "Bashkir",
    "be": "Belarus",
    "bg": "Bulgaria",
    "bh": "Bihari",
    "bi": "Bislama",
    "bm": "Bambara",
    "bn": "Benggala",
    "bo": "Tibet",
    "br": "Breton",
    "bs": "Bosnia",
    "ca": "Catalonia",
    "ce": "Chechen",
    "ch": "Chamorro",
    "co": "Corsica",
    "cr": "Cree",
    "cs": "Czech",
    "cu": "Slavik Gereja",
    "cv": "Chuvash",
    "cy": "Wales",
    "da": "Denmark",
    "de": "Jerman",
    "dv": "Divehi",
    "dz": "Dzongkha",
    "ee": "Ewe",
    "el": "Greek",
    "en": "Inggeris",
    "eo": "Esperanto",
    "es": "Sepanyol",
    "et": "Estonia",
    "eu": "Basque",
    "fa": "Parsi",
    "ff": "Fulah",
    "fi": "Finland",
    "fj": "Fiji",
    "fo": "Faroe",
    "fr": "Perancis",
    "fy": "Frisian",
    "ga": "Ireland",
    "gd": "Scots Gaelic",
    "gl": "Galicia",
    "gn": "Guarani",
    "gu": "Gujerat",
    "gv": "Manx",
    "ha": "Hausa",
    "he": "Ibrani",
    "hi": "Hindi",
    "ho": "Hiri Motu",
    "hr": "Croat",
    "ht": "Haiti",
    "hu": "Hungary",
    "hy": "Armenia",
    "hz": "Herero",
    "ia": "Interlingua",
    "id": "Indonesia",
    "ie": "Interlingue",
    "ig": "Igbo",
    "ii": "Sichuan Yi",
    "ik": "Inupiaq",
    "io": "Ido",
    "is": "Iceland",
    "it": "Itali",
    "iu": "Inuktitut",
    "ja": "Jepun",
    "jv": "Jawa",
    "ka": "Georgia",
    "kg": "Kongo",
    "ki": "Kikuya",
    "kj": "Kuanyama",
    "kk": "Kazakhstan",
    "kl": "Kalaallisut",
    "km": "Khmer",
    "kn": "Kannada",
    "ko": "Korea",
    "kr": "Kanuri",
    "ks": "Kashmir",
    "ku": "Kurdish",
    "kv": "Komi",
    "kw": "Cornish",
    "ky": "Kirghiz",
    "la": "Latin",
    "lb": "Luxembourg",
    "lg": "Ganda",
    "li": "Limburgish",
    "ln": "Lingala",
    "lo": "Laos",
    "lt": "Lithuania",
    "lu": "Luba-Katanga",
    "lv": "Latvia",
    "mg": "Malagasy",
    "mh": "Marshallese",
    "mi": "Maori",
    "mk": "Macedonia",
    "ml": "Malayalam",
    "mn": "Mongolia",
    "mr": "Marathi",
    "ms": "Bahasa Melayu",
    "mt": "Malta",
    "my": "Burma",
    "na": "Nauru",
    "nb": "Bokmål Norway",
    "nd": "Ndebele Utara",
    "ne": "Nepal",
    "ng": "Ndonga",
    "nl": "Belanda",
    "nn": "Nynorsk Norway",
    "no": "Norway",
    "nr": "South Ndebele",
    "nv": "Navajo",
    "ny": "Nyanja",
    "oc": "Occitania",
    "oj": "Ojibwa",
    "om": "Oromo",
    "or": "Oriya",
    "os": "Ossete",
    "pa": "Punjabi",
    "pi": "Pali",
    "pl": "Poland",
    "ps": "Pashto",
    "pt": "Portugis",
    "qu": "Quechua",
    "rm": "Romansh",
    "rn": "Rundi",
    "ro": "Romania",
    "ru": "Rusia",
    "rw": "Kinyarwanda",
    "sa": "Sanskrit",
    "sc": "Sardinian",
    "sd": "Sindhi",
    "se": "Sami Utara",
    "sg": "Sango",
    "si": "Sinhala",
    "sk": "Slovak",
    "sl": "Slovenia",
    "sm": "Samoa",
    "sn": "Shona",
    "so": "Somali",
    "sq": "Albania",
    "sr": "Serbia",
    "ss": "Swati",
    "st": "Sotho Selatan",
    "su": "Sunda",
    "sv": "Sweden",
    "sw": "Swahili",
    "ta": "Tamil",
    "te": "Telugu",
    "tg": "Tajik",
    "th": "Thai",
    "ti": "Tigrinya",
    "tk": "Turkmen",
    "tl": "Tagalog",
    "tn": "Tswana",
    "to": "Tonga",
    "tr": "Turki",
    "ts": "Tsonga",
    "tt": "Tatar",
    "tw": "Twi",
    "ty": "Tahiti",
    "ug": "Uyghur",
    "uk": "Ukraine",
    "ur": "Urdu",
    "uz": "Uzbekistan",
    "ve": "Venda",
    "vi": "Vietnam",
    "vo": "Volapük",
    "wa": "Walloon",
    "wo": "Wolof",
    "xh": "Xhosa",
    "yi": "Yiddish",
    "yo": "Yoruba",
    "za": "Zhuang",
    "zh": "Cina",
    "zu": "Zulu"
  }
}