{
  "locale": "br",
  "languages": {
    "aa": "Afareg",
    "ab": "Abc'hazeg",
    "ae": "Avesteg",
    "af": "Afrikaneg",
    "ak": "Akaneg",
    "am": "Amhareg",
    "an": "Aragoneg",
    "ar": "Arabeg",
    "as": "Asameg",
    "av": "Avareg",
    "ay": "Aymareg",
    "az": "Azeri",
    "ba": "Bachkireg",
    "be": "Belaruseg",
    "bg": "Bulgareg",
    "bh": "Bihareg",
    "bi": "Bislamaeg",
    "bm": "Bambareg",
    "bn": "Banglaeg",
    "bo": "Tibeteg",
    "br": "Brezhoneg",
    "bs": "Bosnieg",
    "ca": "Katalaneg",
    "ce": "Tchetcheneg",
    "ch": "Tchamoroueg",
    "co": "Korseg",
    "cr": "Kreeg",
    "cs": "Tchekeg",
    "cu": "Henslaveg an Iliz",
    "cv": "Tchouvacheg",
    "cy": "Kembraeg",
    "da": "Daneg",
    "de": "Alamaneg",
    "dv": "Diheveg",
    "dz": "Boutaneg (dzongkha)",
    "ee": "Eweeg",
    "el": "Gresianeg",
    "en": "Saozneg",
    "eo": "Esperanteg",
    "es": "Spagnoleg",
    "et": "Estoneg",
    "eu": "Euskareg",
    "fa": "Perseg",
    "ff": "Fouleg",
    "fi": "Finneg",
    "fj": "Fidjieg",
    "fo": "Faroeg",
    "fr": "Galleg",
    "fy": "Frizeg ar c'hornôg",
    "ga": "Iwerzhoneg",
    "gd": "Gouezeleg Skos",
    "gl": "Galizeg",
    "gn": "Gwaranieg",
    "gu": "Goudjarateg",
    "gv": "Manaveg",
    "ha": "Haousaeg",
    "he": "Hebraeg",
    "hi": "Hindeg",
    "ho": "Hirimotoueg",
    "hr": "Kroateg",
    "ht": "Kreol Haiti",
    "hu": "Hungareg",
    "hy": "Armenieg",
    "hz": "Hereroeg",
    "ia": "Interlingua",
    "id": "Indonezeg",
    "ie": "Interlingue",
    "ig": "Igboeg",
    "ii": "Yieg",
    "ik": "Inupiaq",
    "io": "Idoeg",
    "is": "Islandeg",
    "it": "Italianeg",
    "iu": "Inuktitut",
    "ja": "Japaneg",
    "jv": "Javaeg",
    "ka": "Jorjieg",
    "kg": "Kongoeg",
    "ki": "Kikuyu",
    "kj": "Kuanyama",
    "kk": "Kazac'heg",
    "kl": "Kalaallisut",
    "km": "Khmer",
    "kn": "Kannadeg",
    "ko": "Koreaneg",
    "kr": "Kanuri",
    "ks": "Kachmireg",
    "ku": "Kurdeg",
    "kv": "Komi",
    "kw": "Kerneveureg",
    "ky": "Kirghiz",
    "la": "Latin",
    "lb": "Luksembourgeg",
    "lg": "Ganda",
    "li": "Limbourgeg",
    "ln": "Lingala",
    "lo": "Laoeg",
    "lt": "Lituaneg",
    "lu": "Luba-Katanga",
    "lv": "Latveg",
    "mg": "Malagasieg",
    "mh": "Marchalleg",
    "mi": "Maorieg",
    "mk": "Makedoneg",
    "ml": "Malayalameg",
    "mn": "Mongoleg",
    "mr": "Marateg",
    "ms": "Malayeg",
    "mt": "Malteg",
    "my": "Burmeg",
    "na": "Naurueg",
    "nb": "Novegeg (bokmål)",
    "nd": "Ndebeleg an norzh",
    "ne": "Nepaleg",
    "ng": "Ndongeg",
    "nl": "Izelvroeg",
    "nn": "Norvegeg (nynorsk)",
    "no": "Norvegeg",
    "nr": "Ndebeleg ar su",
    "nv": "Navac'hoeg",
    "ny": "Thitcheweg",
    "oc": "Okitaneg",
    "oj": "Ojibweg",
    "om": "Oromoeg",
    "or": "Oriyeg",
    "os": "Oseteg",
    "pa": "Pandajabeg",
    "pi": "Palieg",
    "pl": "Poloneg",
    "ps": "Pachtoueg",
    "pt": "Portugaleg",
    "qu": "Ketchwaeg",
    "rm": "Romancheg",
    "rn": "Roundieg",
    "ro": "Roumaneg",
    "ru": "Ruseg",
    "rw": "Rwandeg",
    "sa": "Sañskriteg",
    "sc": "Sardinieg",
    "sd": "Sindeg",
    "se": "Samieg an norzh",
    "sg": "Sangoeg",
    "si": "Sinhaleg",
    "sk": "Slovakeg",
    "sl": "Sloveneg",
    "sm": "Samoaeg",
    "sn": "Choneg",
    "so": "Somalieg",
    "sq": "Albaneg",
    "sr": "Serbeg",
    "ss": "Swatieg",
    "st": "Sothoeg",
    "su": "Soundaneg",
    "sv": "Svedeg",
    "sw": "Swahilieg",
    "ta": "Tamileg",
    "te": "Telougoueg",
    "tg": "Tadjikeg",
    "th": "Taieg",
    "ti": "Tigrinya",
    "tk": "Turkmeneg",
    "tl": "Tagalogeg",
    "tn": "Tswana",
    "to": "Tonga",
    "tr": "Turkeg",
    "ts": "Tsonga",
    "tt": "Tatareg",
    "tw": "Twieg",
    "ty": "Tahitieg",
    "ug": "Uighur",
    "uk": "Ukraineg",
    "ur": "Ourdoueg",
    "uz": "Ouzbekeg",
    "ve": "Vendeg",
    "vi": "Vietnameg",
    "vo": "Volapukeg",
    "wa": "Walloneg",
    "wo": "Wolofeg",
    "xh": "Xhosaeg",
    "yi": "Yidicheg",
    "yo": "Yoroubeg",
    "za": "Djouangeg",
    "zh": "sinalunioù",
    "zu": "Zouloueg"
  }
}