{
  "locale": "is",
  "languages": {
    "aa": "Afar",
    "ab": "Abkasíska",
    "ae": "Avestíska",
    "af": "Afríkanska",
    "ak": "Akanmál",
    "am": "Amharíska",
    "an": "Aragónska",
    "ar": "Arabíska",
    "as": "Assameíska",
    "av": "Avarska",
    "ay": "Aímaríska",
    "az": "Aserbaídsjanska",
    "ba": "Bashkir",
    "be": "Hvítrússneska",
    "bg": "Búlgarska",
    "bh": "Bihari",
    "bi": "Bislama",
    "bm": "Bambara",
    "bn": "Bengalska",
    "bo": "Tibetan",
    "br": "Bretónska",
    "bs": "Bosníska",
    "ca": "Katalónska",
    "ce": "Téténska",
    "ch": "Chamorro",
    "co": "Corsican",
    "cr": "Cree",
    "cs": "Tékkneska",
    "cu": "Church Slavic",
    "cv": "Chuvash",
    "cy": "Velska",
    "da": "Danska",
    "de": "Þýska",
    "dv": "Divehi",
    "dz": "Dzongkha",
    "ee": "Anló",
    "el": "Gríska",
    "en": "Enska",
    "eo": "Esperantó",
    "es": "Spænska",
    "et": "Eistneska",
    "eu": "Baskneska",
    "fa": "Persneska",
    "ff": "Fulah",
    "fi": "Finnska",
    "fj": "Fídjeyska",
    "fo": "Færeyska",
    "fr": "Franska",
    "fy": "Frísneska",
    "ga": "Írska",
    "gd": "Gelíska",
    "gl": "Galisíska",
    "gn": "Guaraní",
    "gu": "Gujarati",
    "gv": "Manska",
    "ha": "Hausa",
    "he": "Hebreska",
    "hi": "Hindí",
    "ho": "Hiri Motu",
    "hr": "Króatíska",
    "ht": "Haitian",
    "hu": "Ungverska",
    "hy": "Armenska",
    "hz": "Herero",
    "ia": "Interlingua",
    "id": "Indónesíska",
    "ie": "Interlingue",
    "ig": "Igbo",
    "ii": "Sichuan Yi",
    "ik": "Inupiaq",
    "io": "Ido",
    "is": "Íslenska",
    "it": "Ítalska",
    "iu": "Inuktitut",
    "ja": "Japanska",
    "jv": "Javanska",
    "ka": "Georgíska",
    "kg": "Kongo",
    "ki": "Kikuyu",
    "kj": "Kwanyama",
    "kk": "Kasakska",
    "kl": "Grænlenska",
    "km": "Khmer",
    "kn": "Kanaríska",
    "ko": "Kóreska",
    "kr": "Kanuri",
    "ks": "Kashmiri",
    "ku": "Kurdish",
    "kv": "Komi",
    "kw": "Cornish",
    "ky": "Kirgisíska",
    "la": "Latína",
    "lb": "Lúxemborgíska",
    "lg": "Ganda",
    "li": "Limburgish",
    "ln": "Lingala",
    "lo": "Lao",
    "lt": "Litháíska",
    "lu": "Luba-Katanga",
    "lv": "Lettneska",
    "mg": "Malagasíska",
    "mh": "Malagasíska",
    "mi": "Māori",
    "mk": "Makedónska",
    "ml": "Malayalam",
    "mn": "Khalkha",
    "mr": "Marathi",
    "ms": "Malasíska",
    "mt": "Maltneska",
    "my": "Búrmíska",
    "na": "Nárúska",
    "nb": "Bókmál",
    "nd": "North Ndebele",
    "ne": "Nepali",
    "ng": ":en:Ndonga",
    "nl": "Hollenska",
    "nn": "Nýnorska",
    "no": "Norska",
    "nr": "Ndebele",
    "nv": "Navajo",
    "ny": "Chichewa",
    "oc": "Occitan",
    "oj": "Ojibwa",
    "om": "Oromo",
    "or": "Oriya",
    "os": "Ossetian",
    "pa": "Púndjabí",
    "pi": "Pāli",
    "pl": "Pólska",
    "ps": "Pashto",
    "pt": "Portúgalska",
    "qu": "Quechua",
    "rm": "Raeto-Romance",
    "rn": "Kirundi",
    "ro": "Rúmenska",
    "ru": "Rússneska",
    "rw": "Kinyarwanda",
    "sa": "Sanskrít",
    "sc": "Sardinian",
    "sd": "Sindhi",
    "se": "Norðursamíska",
    "sg": "Sango",
    "si": "Sinhalese",
    "sk": "Slóvakíska",
    "sl": "Slóvenska",
    "sm": "Samóska",
    "sn": "Shona",
    "so": "Sómalska",
    "sq": "Albanska",
    "sr": "Serbneska",
    "ss": "Svasí",
    "st": "Sótó",
    "su": "Sundanese",
    "sv": "Sænska",
    "sw": "Svahílí",
    "ta": "Tamílska",
    "te": "Telúgú",
    "tg": "Tajik",
    "th": "Taílenska",
    "ti": "Tigrinya",
    "tk": "Túrkmenska",
    "tl": "Tagalog",
    "tn": "Tsvana",
    "to": "Tongverska",
    "tr": "Tyrkneska",
    "ts": "Tsonga",
    "tt": "Tatar",
    "tw": "Tví",
    "ty": "Tahitian",
    "ug": "Uighur",
    "uk": "Úkraínska",
    "ur": "Úrdú",
    "uz": "Úsbekíska",
    "ve": "Venda",
    "vi": "Víetnamska",
    "vo": ":en:Volapük",
    "wa": "Walloon",
    "wo": "Wolof",
    "xh": "Xhosa",
    "yi": "Yiddish",
    "yo": "Yoruba",
    "za": "Zhuang",
    "zh": "Kínverska",
    "zu": "Súlúmál"
  }
}