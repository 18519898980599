{
  "locale": "th",
  "languages": {
    "aa": "อะฟาร์",
    "ab": "อับคาซ",
    "ae": "อเวสตะ",
    "af": "แอฟริกานส์",
    "ak": "อาคัน",
    "am": "อัมฮารา",
    "an": "อารากอน",
    "ar": "อาหรับ",
    "as": "อัสสัม",
    "av": "อาวาร์",
    "ay": "ไอย์มารา",
    "az": "อาเซอร์ไบจาน",
    "ba": "บัชคีร์",
    "be": "เบลารุส",
    "bg": "บัลแกเรีย",
    "bh": "พิหาร",
    "bi": "บิสลามา",
    "bm": "บัมบารา",
    "bn": "เบงกาลี",
    "bo": "ทิเบต",
    "br": "เบรตัน",
    "bs": "บอสเนีย",
    "ca": "กาตาลัง",
    "ce": "เชเชน",
    "ch": "ชามอร์โร",
    "co": "คอร์ซิกา",
    "cr": "ครี",
    "cs": "เช็ก",
    "cu": "เชอร์ชสลาวิก",
    "cv": "ชูวัช",
    "cy": "เวลส์",
    "da": "เดนมาร์ก",
    "de": "เยอรมัน",
    "dv": "ธิเวหิ",
    "dz": "ซองคา",
    "ee": "เอเว",
    "el": "กรีก",
    "en": "อังกฤษ",
    "eo": "เอสเปอรันโต",
    "es": "สเปน",
    "et": "เอสโตเนีย",
    "eu": "บัสเก",
    "fa": "เปอร์เซีย",
    "ff": "ฟูลาฮ์",
    "fi": "ฟินแลนด์",
    "fj": "ฟิจิ",
    "fo": "แฟโร",
    "fr": "ฝรั่งเศส",
    "fy": "ฟริเซียนตะวันตก",
    "ga": "ไอริช",
    "gd": "สกอตส์กาลิก",
    "gl": "กาลิเซีย",
    "gn": "กวารานี",
    "gu": "คุชราต",
    "gv": "มานซ์",
    "ha": "เฮาชา",
    "he": "ฮิบรู",
    "hi": "ฮินดี",
    "ho": "ฮีรีโมตู",
    "hr": "โครเอเชีย",
    "ht": "เฮติ",
    "hu": "ฮังการี",
    "hy": "อาร์เมเนีย",
    "hz": "เฮเรโร",
    "ia": "อินเตอร์ลิงกัว",
    "id": "อินโดนีเชีย",
    "ie": "อินเตอร์ลิงกิว",
    "ig": "อิกโบ",
    "ii": "เสฉวนยิ",
    "ik": "อีนูเปียก",
    "io": "อีโด",
    "is": "ไอซ์แลนด์",
    "it": "อิตาลี",
    "iu": "อินุกติตุต",
    "ja": "ญี่ปุ่น",
    "jv": "ชวา",
    "ka": "จอร์เจีย",
    "kg": "คองโก",
    "ki": "กีกูยู",
    "kj": "กวนยามา",
    "kk": "คาซัค",
    "kl": "กรีนแลนด์",
    "km": "เขมร",
    "kn": "กันนาดา",
    "ko": "เกาหลี",
    "kr": "คานูรี",
    "ks": "กัศมีร์",
    "ku": "เคิร์ด",
    "kv": "โกมิ",
    "kw": "คอร์นิช",
    "ky": "คีร์กีซ",
    "la": "ละติน",
    "lb": "ลักเซมเบิร์ก",
    "lg": "ยูกันดา",
    "li": "ลิมเบิร์ก",
    "ln": "ลิงกาลา",
    "lo": "ลาว",
    "lt": "ลิทัวเนีย",
    "lu": "ลูบา-กาตองกา",
    "lv": "ลัตเวีย",
    "mg": "มาลากาซี",
    "mh": "มาร์แชลลิส",
    "mi": "เมารี",
    "mk": "มาซิโดเนีย",
    "ml": "มาลายาลัม",
    "mn": "มองโกเลีย",
    "mr": "มราฐี",
    "ms": "มาเลย์",
    "mt": "มอลตา",
    "my": "พม่า",
    "na": "นาอูรู",
    "nb": "นอร์เวย์บุคมอล",
    "nd": "เอ็นเดเบเลเหนือ",
    "ne": "เนปาล",
    "ng": "ดองกา",
    "nl": "ดัตช์",
    "nn": "นอร์เวย์นีนอสก์",
    "no": "นอร์เวย์",
    "nr": "เอ็นเดเบเลใต้",
    "nv": "นาวาโฮ",
    "ny": "เนียนจา",
    "oc": "อ็อกซิตัน",
    "oj": "โอจิบวา",
    "om": "โอโรโม",
    "or": "โอริยา",
    "os": "ออสเซเตีย",
    "pa": "ปัญจาบ",
    "pi": "บาลี",
    "pl": "โปแลนด์",
    "ps": "พาชตู",
    "pt": "โปรตุเกส",
    "qu": "ควิชัว",
    "rm": "โรแมนซ์",
    "rn": "บุรุนดี",
    "ro": "โรมาเนีย",
    "ru": "รัสเซีย",
    "rw": "รวันดา",
    "sa": "สันสกฤต",
    "sc": "ซาร์เดญา",
    "sd": "สินธุ",
    "se": "ซามิเหนือ",
    "sg": "แซงโก",
    "si": "สิงหล",
    "sk": "สโลวัก",
    "sl": "สโลวีเนีย",
    "sm": "ซามัว",
    "sn": "โชนา",
    "so": "โซมาลี",
    "sq": "แอลเบเนีย",
    "sr": "เซอร์เบีย",
    "ss": "สวาติ",
    "st": "โซโทใต้",
    "su": "ซุนดา",
    "sv": "สวีเดน",
    "sw": "สวาฮีลี",
    "ta": "ทมิฬ",
    "te": "เตลูกู",
    "tg": "ทาจิก",
    "th": "ไทย",
    "ti": "ติกริญญา",
    "tk": "เติร์กเมนิสถาน",
    "tl": "ตากาล็อก",
    "tn": "บอตสวานา",
    "to": "ตองกา",
    "tr": "ตุรกี",
    "ts": "ซิิตซองกา",
    "tt": "ตาตาร์",
    "tw": "ทวิ",
    "ty": "ตาฮิตี",
    "ug": "อุยกัว",
    "uk": "ยูเครน",
    "ur": "อูรดู",
    "uz": "อุซเบก",
    "ve": "เวนดา",
    "vi": "เวียดนาม",
    "vo": "โวลาพึค",
    "wa": "วาโลนี",
    "wo": "โวลอฟ",
    "xh": "คะห์โอซา",
    "yi": "ยิว",
    "yo": "โยรูบา",
    "za": "จ้วง",
    "zh": "จีน",
    "zu": "ซูลู"
  }
}