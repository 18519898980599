{
  "locale": "lt",
  "languages": {
    "aa": "Afarų kalba",
    "ab": "Abchazų kalba",
    "ae": "Avestos kalba",
    "af": "Afrikanų kalba",
    "ak": "Akanų kalba",
    "am": "Amherų kalba",
    "an": "Aragoniečių kalba",
    "ar": "arb",
    "as": "Asamų kalba",
    "av": "Avarų kalba",
    "ay": "Aimarų kalba",
    "az": "Azerbaidžaniečių kalba",
    "ba": "Baškirų kalba",
    "be": "Baltarusių kalba",
    "bg": "Bulgarų kalba",
    "bh": "Biharų kalba",
    "bi": "Bislama",
    "bm": "Bambarų kalba",
    "bn": "Bengalų kalba",
    "bo": "Tibetiečių kalba",
    "br": "Bretonų kalba",
    "bs": "Bosnių kalba",
    "ca": "Katalonų kalba",
    "ce": "Čečėnų kalba",
    "ch": "Čamorų kalba",
    "co": "Korsikiečių kalba",
    "cr": "Kri kalba",
    "cs": "Čekų kalba",
    "cu": "Bažnytinė slavų kalba",
    "cv": "Čiuvašų kalba",
    "cy": "Valų kalba",
    "da": "Danų kalba",
    "de": "Vokiečių kalba",
    "dv": "Maldyviečių kalba",
    "dz": "Botijų kalba",
    "ee": "Eve kalba",
    "el": "Graikų kalba",
    "en": "Anglų kalba",
    "eo": "Esperanto",
    "es": "Ispanų kalba",
    "et": "Estų kalba",
    "eu": "Baskų kalba",
    "fa": "Persų kalba",
    "ff": "Fulų kalba",
    "fi": "Suomių kalba",
    "fj": "Fidžių kalba",
    "fo": "Farerų kalba",
    "fr": "Prancūzų kalba",
    "fy": "Fryzų kalba",
    "ga": "Airių kalba",
    "gd": "Škotų gėlų kalba",
    "gl": "Galisų kalba",
    "gn": "Gvaranių kalba",
    "gu": "Gudžaratų kalba",
    "gv": "Menksiečių kalba",
    "ha": "Hausų kalba",
    "he": "Hebrajų kalba",
    "hi": "Hindi",
    "ho": "Hiri Motu",
    "hr": "Kroatų kalba",
    "ht": "Haičio kreolų kalba",
    "hu": "Vengrų kalba",
    "hy": "Armėnų kalba",
    "hz": "Herero kalba",
    "ia": "Interlingua",
    "id": "Indoneziečių kalba",
    "ie": "Interlingue",
    "ig": "Igbų kalba",
    "ii": "Ji kalba",
    "ik": "Inupiakų kalba",
    "io": "Ido",
    "is": "Islandų kalba",
    "it": "Italų kalba",
    "iu": "Inuktitutas",
    "ja": "Japonų kalba",
    "jv": "Javiečių kalba",
    "ka": "Gruzinų kalba",
    "kg": "Kongo kalba",
    "ki": "Kikujų kalba",
    "kj": "Kvanjama kalba",
    "kk": "Kazachų kalba",
    "kl": "Grenlandų kalba",
    "km": "Kmerų kalba",
    "kn": "Kanadų kalba",
    "ko": "Korėjiečių kalba",
    "kr": "Kanurių kalba",
    "ks": "Kašmyriečių kalba",
    "ku": "Kurdų kalba",
    "kv": "Komių kalba",
    "kw": "Kornų kalba",
    "ky": "Kirgizų kalba",
    "la": "Lotynų kalba",
    "lb": "Liuksemburgiečių kalba",
    "lg": "Lugandų kalba",
    "li": "Limburgiečių kalba",
    "ln": "Lingalų kalba",
    "lo": "Lao kalba",
    "lt": "Lietuvių kalba",
    "lu": "Lubų kalba",
    "lv": "Latvių kalba",
    "mg": "Malagasių kalba",
    "mh": "Maršaliečių kalba",
    "mi": "Maorių kalba",
    "mk": "Makedonų kalba",
    "ml": "Malajalių kalba",
    "mn": "Mongolų kalba",
    "mr": "Maratų kalba",
    "ms": "mly",
    "mt": "Maltiečių kalba",
    "my": "Mjanmų kalba",
    "na": "Nauriečių kalba",
    "nb": "Norvegų kalba",
    "nd": "Šiaurės ndebelų kalba",
    "ne": "Nepalų kalba",
    "ng": "Ndongų kalba",
    "nl": "Olandų kalba",
    "nn": "Naujoji norvegų kalba",
    "no": "Norvegų kalba",
    "nr": "Pietų ndebelų kalba",
    "nv": "Navachų kalba",
    "ny": "Čičevų kalba",
    "oc": "Oksitanų kalba",
    "oj": "Odžibvių kalba",
    "om": "Oromų kalba",
    "or": "Orijų kalba",
    "os": "Osetinų kalba",
    "pa": "Pendžabų kalba",
    "pi": "Pali kalba",
    "pl": "Lenkų kalba",
    "ps": "Puštūnų kalba",
    "pt": "Portugalų kalba",
    "qu": "Kečujų kalba",
    "rm": "Retoromanų kalba",
    "rn": "Kirundžių kalba",
    "ro": "Rumunų kalba",
    "ru": "Rusų kalba",
    "rw": "Kinjaruanda",
    "sa": "Sanskritas",
    "sc": "Sardų kalba",
    "sd": "Sindžų kalba",
    "se": "Šiaurės samių kalba",
    "sg": "Sangų kalba",
    "si": "Sinhalų kalba",
    "sk": "Slovakų kalba",
    "sl": "Slovėnų kalba",
    "sm": "Samoa kalba",
    "sn": "Šonų kalba",
    "so": "Somalių kalba",
    "sq": "Albanų kalba",
    "sr": "Serbų kalba",
    "ss": "Svazių kalba",
    "st": "Pietų sotų kalba",
    "su": "Sundų kalba",
    "sv": "Švedų kalba",
    "sw": "Suahilių kalba",
    "ta": "Tamilų kalba",
    "te": "Telugų kalba",
    "tg": "Tadžikų kalba",
    "th": "Tajų kalba",
    "ti": "Tigrinijų kalba",
    "tk": "Turkmėnų kalba",
    "tl": "Tagalų kalba",
    "tn": "Tsvanų kalba",
    "to": "Tongos kalba",
    "tr": "Turkų kalba",
    "ts": "Tsongų kalba",
    "tt": "Totorių kalba",
    "tw": "Twi",
    "ty": "Reo",
    "ug": "Uigūrų kalba",
    "uk": "Ukrainiečių kalba",
    "ur": "Urdu",
    "uz": "Uzbekų kalba",
    "ve": "Vendų kalba",
    "vi": "Vietnamiečių kalba",
    "vo": "Volapiukas",
    "wa": "Valonų kalba",
    "wo": "Volofų kalba",
    "xh": "Kosų kalba",
    "yi": "Jidiš",
    "yo": "Jorubų kalba",
    "za": "Džuangų kalba",
    "zh": "Kinų kalba",
    "zu": "Zulų kalba"
  }
}