{
  "locale": "id",
  "languages": {
    "aa": "Afar",
    "ab": "Abkhaz",
    "ae": "Avesta",
    "af": "Afrikaans",
    "ak": "Akan",
    "am": "Amharik",
    "an": "Aragon",
    "ar": "Arab",
    "as": "Assam",
    "av": "Avar",
    "ay": "Aymara",
    "az": "Azerbaijan",
    "ba": "Bashkir",
    "be": "Belarusia",
    "bg": "Bulgar",
    "bh": "Bihari",
    "bi": "Bislama",
    "bm": "Bambara",
    "bn": "Bengali",
    "bo": "Tibet",
    "br": "Breton",
    "bs": "Bosnia",
    "ca": "Katalan",
    "ce": "Chechen",
    "ch": "Chamorro",
    "co": "Korsika",
    "cr": "Kree",
    "cs": "Cheska",
    "cu": "Bahasa Gereja Slavonia",
    "cv": "Chuvash",
    "cy": "Welsh",
    "da": "Dansk",
    "de": "Jerman",
    "dv": "Divehi",
    "dz": "Dzongkha",
    "ee": "Ewe",
    "el": "Yunani",
    "en": "Inggris",
    "eo": "Esperanto",
    "es": "Spanyol",
    "et": "Esti",
    "eu": "Bask",
    "fa": "Persia",
    "ff": "Fula",
    "fi": "Suomi",
    "fj": "Fiji",
    "fo": "Faro",
    "fr": "Prancis",
    "fy": "Frisia Barat",
    "ga": "Irlandia",
    "gd": "Gaelik Skotlandia",
    "gl": "Galisia",
    "gn": "Guarani",
    "gu": "Gujarati",
    "gv": "Manx",
    "ha": "Hausa",
    "he": "Ibrani",
    "hi": "Hindi",
    "ho": "Hiri Motu",
    "hr": "Kroasia",
    "ht": "Haiti",
    "hu": "Hungaria",
    "hy": "Armenia",
    "hz": "Herero",
    "ia": "Interlingua",
    "id": "Bahasa Indonesia",
    "ie": "Interlingue",
    "ig": "Igbo",
    "ii": "Sichuan Yi",
    "ik": "Inupiak",
    "io": "Ido",
    "is": "Islandia",
    "it": "Italia",
    "iu": "Inuktitut",
    "ja": "Jepang",
    "jv": "Jawa",
    "ka": "Georgia",
    "kg": "Kongo",
    "ki": "Kikuyu",
    "kj": "Kuanyama",
    "kk": "Kazakh",
    "kl": "Kalaallisut",
    "km": "Khmer",
    "kn": "Kannada",
    "ko": "Korea",
    "kr": "Kanuri",
    "ks": "Kashmir",
    "ku": "Kurdi",
    "kv": "Komi",
    "kw": "Kornish",
    "ky": "Kirgiz",
    "la": "Latin",
    "lb": "Luksemburg",
    "lg": "Ganda",
    "li": "Limburgia",
    "ln": "Lingala",
    "lo": "Lao",
    "lt": "Lituavi",
    "lu": "Luba-Katanga",
    "lv": "Latvi",
    "mg": "Malagasi",
    "mh": "Marshall",
    "mi": "Maori",
    "mk": "Makedonia",
    "ml": "Malayalam",
    "mn": "Mongolia",
    "mr": "Marathi",
    "ms": "Melayu",
    "mt": "Malta",
    "my": "Burma",
    "na": "Nauru",
    "nb": "Bokmål Norwegia",
    "nd": "Ndebele Utara",
    "ne": "Nepali",
    "ng": "Ndonga",
    "nl": "Belanda",
    "nn": "Nynorsk Norwegia",
    "no": "Norwegia",
    "nr": "Ndebele Selatan",
    "nv": "Navajo",
    "ny": "Nyanja",
    "oc": "Ositania",
    "oj": "Ojibwa",
    "om": "Oromo",
    "or": "Oriya",
    "os": "Ossetia",
    "pa": "Punjabi",
    "pi": "Pali",
    "pl": "Polski",
    "ps": "Pashto",
    "pt": "Portugis",
    "qu": "Quechua",
    "rm": "Reto-Roman",
    "rn": "Rundi",
    "ro": "Rumania",
    "ru": "Rusia",
    "rw": "Kinyarwanda",
    "sa": "Sanskerta",
    "sc": "Sardinia",
    "sd": "Sindhi",
    "se": "Sami Utara",
    "sg": "Sango",
    "si": "Sinhala",
    "sk": "Slovak",
    "sl": "Sloven",
    "sm": "Samoa",
    "sn": "Shona",
    "so": "Somali",
    "sq": "Albania",
    "sr": "Serb",
    "ss": "Swati",
    "st": "Sotho Selatan",
    "su": "Sunda",
    "sv": "Swedia",
    "sw": "Swahili",
    "ta": "Tamil",
    "te": "Telugu",
    "tg": "Tajik",
    "th": "Thai",
    "ti": "Tigrinya",
    "tk": "Turkmen",
    "tl": "Tagalog",
    "tn": "Tswana",
    "to": "Tonga",
    "tr": "Turki",
    "ts": "Tsonga",
    "tt": "Tatar",
    "tw": "Twi",
    "ty": "Tahiti",
    "ug": "Uyghur",
    "uk": "Ukraina",
    "ur": "Urdu",
    "uz": "Uzbek",
    "ve": "Venda",
    "vi": "Vietnam",
    "vo": "Volapuk",
    "wa": "Walloon",
    "wo": "Wolof",
    "xh": "Xhosa",
    "yi": "Yiddish",
    "yo": "Yoruba",
    "za": "Zhuang",
    "zh": "China",
    "zu": "Zulu"
  }
}