{
  "locale": "vi",
  "languages": {
    "aa": "Tiếng Afar",
    "ab": "Tiếng Abkhazia",
    "ae": "Tiếng Avestan",
    "af": "Tiếng Nam Phi",
    "ak": "Tiếng Akan",
    "am": "Tiếng Amharic",
    "an": "Tiếng Aragon",
    "ar": "Tiếng Ả Rập",
    "as": "Tiếng Assam",
    "av": "Tiếng Avaric",
    "ay": "Tiếng Aymara",
    "az": "Tiếng Azerbaijan",
    "ba": "Tiếng Bashkir",
    "be": "Tiếng Belarus",
    "bg": "Tiếng Bulgaria",
    "bh": "Tiếng Bihari",
    "bi": "Tiếng Bislama",
    "bm": "Tiếng Bambara",
    "bn": "Tiếng Bengali",
    "bo": "Tiếng Tây Tạng",
    "br": "Tiếng Breton",
    "bs": "Tiếng Nam Tư",
    "ca": "Tiếng Catalan",
    "ce": "Tiếng Chechen",
    "ch": "Tiếng Chamorro",
    "co": "Tiếng Corsica",
    "cr": "Tiếng Cree",
    "cs": "Tiếng Séc",
    "cu": "Tiếng Slavơ Nhà thờ",
    "cv": "Tiếng Chuvash",
    "cy": "Tiếng Wales",
    "da": "Tiếng Đan Mạch",
    "de": "Tiếng Đức",
    "dv": "Tiếng Divehi",
    "dz": "Tiếng Dzongkha",
    "ee": "Tiếng Ewe",
    "el": "Tiếng Hy Lạp",
    "en": "Tiếng Anh",
    "eo": "Tiếng Quốc Tế Ngữ",
    "es": "Tiếng Tây Ban Nha",
    "et": "Tiếng Estonia",
    "eu": "Tiếng Basque",
    "fa": "Tiếng Ba Tư",
    "ff": "Tiếng Fulah",
    "fi": "Tiếng Phần Lan",
    "fj": "Tiếng Fiji",
    "fo": "Tiếng Faore",
    "fr": "Tiếng Pháp",
    "fy": "Tiếng Frisia",
    "ga": "Tiếng Ai-len",
    "gd": "Tiếng Xentơ (Xcốt len)",
    "gl": "Tiếng Galician",
    "gn": "Tiếng Guarani",
    "gu": "Tiếng Gujarati",
    "gv": "Tiếng Manx",
    "ha": "Tiếng Hausa",
    "he": "Tiếng Do Thái",
    "hi": "Tiếng Hindi",
    "ho": "Tiếng Hiri Motu",
    "hr": "Tiếng Croatia",
    "ht": "Tiếng Haiti",
    "hu": "Tiếng Hungary",
    "hy": "Tiếng Armenia",
    "hz": "Tiếng Herero",
    "ia": "Tiếng Khoa Học Quốc Tế",
    "id": "Tiếng Indonesia",
    "ie": "Tiếng Interlingue",
    "ig": "Tiếng Igbo",
    "ii": "Tiếng Di Tứ Xuyên",
    "ik": "Tiếng Inupiaq",
    "io": "Tiếng Ido",
    "is": "Tiếng Iceland",
    "it": "Tiếng Ý",
    "iu": "Tiếng Inuktitut",
    "ja": "Tiếng Nhật",
    "jv": "Tiếng Java",
    "ka": "Tiếng Gruzia",
    "kg": "Tiếng Kongo",
    "ki": "Tiếng Kikuyu",
    "kj": "Tiếng Kuanyama",
    "kk": "Tiếng Kazakh",
    "kl": "Tiếng Kalaallisut",
    "km": "Tiếng Khơ-me",
    "kn": "Tiếng Kannada",
    "ko": "Tiếng Hàn",
    "kr": "Tiếng Kanuri",
    "ks": "Tiếng Kashmiri",
    "ku": "Tiếng Kurd",
    "kv": "Tiếng Komi",
    "kw": "Tiếng Cornwall",
    "ky": "Tiếng Kyrgyz",
    "la": "Tiếng La-tinh",
    "lb": "Tiếng Luxembourg",
    "lg": "Tiếng Ganda",
    "li": "Tiếng Limburg",
    "ln": "Tiếng Lingala",
    "lo": "Tiếng Lào",
    "lt": "Tiếng Lít-va",
    "lu": "Tiếng Luba-Katanga",
    "lv": "Tiếng Latvia",
    "mg": "Tiếng Malagasy",
    "mh": "Tiếng Marshall",
    "mi": "Tiếng Maori",
    "mk": "Tiếng Macedonia",
    "ml": "Tiếng Malayalam",
    "mn": "Tiếng Mông Cổ",
    "mr": "Tiếng Marathi",
    "ms": "Tiếng Malaysia",
    "mt": "Tiếng Malt",
    "my": "Tiếng Miến Điện",
    "na": "Tiếng Nauru",
    "nb": "Tiếng Na Uy (Bokmål)",
    "nd": "Tiếng Ndebele Miền Bắc",
    "ne": "Tiếng Nepal",
    "ng": "Tiếng Ndonga",
    "nl": "Tiếng Hà Lan",
    "nn": "Tiếng Na Uy (Nynorsk)",
    "no": "Tiếng Na Uy",
    "nr": "Tiếng Ndebele Miền Nam",
    "nv": "Tiếng Navajo",
    "ny": "Tiếng Nyanja",
    "oc": "Tiếng Occitan",
    "oj": "Tiếng Ojibwa",
    "om": "Tiếng Oromo",
    "or": "Tiếng Oriya",
    "os": "Tiếng Ossetic",
    "pa": "Tiếng Punjab",
    "pi": "Tiếng Pali",
    "pl": "Tiếng Ba Lan",
    "ps": "Tiếng Pashto",
    "pt": "Tiếng Bồ Đào Nha",
    "qu": "Tiếng Quechua",
    "rm": "Tiếng Romansh",
    "rn": "Tiếng Rundi",
    "ro": "Tiếng Rumani",
    "ru": "Tiếng Nga",
    "rw": "Tiếng Kinyarwanda",
    "sa": "Tiếng Phạn",
    "sc": "Tiếng Sardinia",
    "sd": "Tiếng Sindhi",
    "se": "Tiếng Sami Miền Bắc",
    "sg": "Tiếng Sango",
    "si": "Tiếng Sinhala",
    "sk": "Tiếng Slovak",
    "sl": "Tiếng Slovenia",
    "sm": "Tiếng Samoa",
    "sn": "Tiếng Shona",
    "so": "Tiếng Somali",
    "sq": "Tiếng An-ba-ni",
    "sr": "Tiếng Serbia",
    "ss": "Tiếng Swati",
    "st": "Tiếng Sesotho",
    "su": "Tiếng Sudan",
    "sv": "Tiếng Thụy Điển",
    "sw": "Tiếng Swahili",
    "ta": "Tiếng Tamil",
    "te": "Tiếng Telugu",
    "tg": "Tiếng Tajik",
    "th": "Tiếng Thái",
    "ti": "Tiếng Tigrigya",
    "tk": "Tiếng Turk",
    "tl": "Tiếng Tagalog",
    "tn": "Tiếng Tswana",
    "to": "Tiếng Tonga",
    "tr": "Tiếng Thổ Nhĩ Kỳ",
    "ts": "Tiếng Tsonga",
    "tt": "Tiếng Tatar",
    "tw": "Tiếng Twi",
    "ty": "Tiếng Tahiti",
    "ug": "Tiếng Uyghur",
    "uk": "Tiếng Ucraina",
    "ur": "Tiếng Uđu",
    "uz": "Tiếng Uzbek",
    "ve": "Tiếng Venda",
    "vi": "Tiếng Việt",
    "vo": "Tiếng Volapük",
    "wa": "Tiếng Walloon",
    "wo": "Tiếng Wolof",
    "xh": "Tiếng Xhosa",
    "yi": "Tiếng Y-đit",
    "yo": "Tiếng Yoruba",
    "za": "Tiếng Zhuang",
    "zh": "Tiếng Trung",
    "zu": "Tiếng Zulu"
  }
}