{
  "locale": "ko",
  "languages": {
    "aa": "아파르어",
    "ab": "압카즈어",
    "ae": "아베스타어",
    "af": "아프리칸스어",
    "ak": "아칸어",
    "am": "암하라어",
    "an": "아라곤어",
    "ar": "아랍어",
    "as": "아샘어",
    "av": "아바릭어",
    "ay": "아이마라어",
    "az": "아제르바이잔어",
    "ba": "바슈키르어",
    "be": "벨라루스어",
    "bg": "불가리아어",
    "bi": "비슬라마어",
    "bm": "밤바라어",
    "bn": "벵골어",
    "bo": "티베트어",
    "br": "브르타뉴어",
    "bs": "보스니아어",
    "ca": "카탈로니아어",
    "ce": "체첸어",
    "ch": "차모로어",
    "co": "코르시카어",
    "cr": "크리어",
    "cs": "체코어",
    "cu": "교회 슬라브어",
    "cv": "추바시어",
    "cy": "웨일스어",
    "da": "덴마크어",
    "de": "독일어",
    "dv": "디베히어",
    "dz": "종카어",
    "ee": "에웨어",
    "el": "그리스어",
    "en": "영어",
    "eo": "에스페란토어",
    "es": "스페인어",
    "et": "에스토니아어",
    "eu": "바스크어",
    "fa": "페르시아어",
    "ff": "풀라어",
    "fi": "핀란드어",
    "fj": "피지어",
    "fo": "페로어",
    "fr": "프랑스어",
    "fy": "프리지아어",
    "ga": "아일랜드어",
    "gd": "스코틀랜드 게일어",
    "gl": "갈리시아어",
    "gn": "과라니어",
    "gu": "구자라트어",
    "gv": "맹크스어",
    "ha": "하우사어",
    "he": "히브리어",
    "hi": "힌디어",
    "ho": "히리 모투어",
    "hr": "크로아티아어",
    "ht": "아이티어",
    "hu": "헝가리어",
    "hy": "아르메니아어",
    "hz": "헤레로어",
    "ia": "인테르링구아 (국제보조어협회)",
    "id": "인도네시아어",
    "ie": "인테르링구에",
    "ig": "이그보어",
    "ii": "쓰촨 이어",
    "ik": "이누피아크어",
    "io": "이도어",
    "is": "아이슬란드어",
    "it": "이탈리아어",
    "iu": "이눅티투트어",
    "ja": "일본어",
    "jv": "자바어",
    "ka": "조지아어",
    "kg": "콩고어",
    "ki": "키쿠유어",
    "kj": "쿠안야마어",
    "kk": "카자흐어",
    "kl": "그린란드어",
    "km": "캄보디아어",
    "kn": "칸나다어",
    "ko": "한국어",
    "kr": "칸누리어",
    "ks": "카슈미르어",
    "ku": "쿠르드어",
    "kv": "코미어",
    "kw": "콘월어",
    "ky": "키르기스어",
    "la": "라틴어",
    "lb": "룩셈부르크어",
    "lg": "간다어",
    "li": "림버거어",
    "ln": "링갈라어",
    "lo": "라오어",
    "lt": "리투아니아어",
    "lu": "루바-카탄가어",
    "lv": "라트비아어",
    "mg": "말라가시어",
    "mh": "마셜제도어",
    "mi": "마오리어",
    "mk": "마케도니아어",
    "ml": "말라얄람어",
    "mn": "몽고어",
    "mr": "마라티어",
    "ms": "말레이어",
    "mt": "몰타어",
    "my": "버마어",
    "na": "나우루어",
    "nb": "노르웨이어(보크말)",
    "nd": "북부 은데벨레어",
    "ne": "네팔어",
    "ng": "느동가어",
    "nl": "네덜란드어",
    "nn": "노르웨이어(니노르스크)",
    "no": "노르웨이어",
    "nr": "남부 은데벨레어",
    "nv": "나바호어",
    "ny": "니안자어; 치츄어; 츄어",
    "oc": "오크어",
    "oj": "오지브웨이어",
    "om": "오로모어",
    "or": "오리야어",
    "os": "오세트어",
    "pa": "펀잡어",
    "pi": "팔리어",
    "pl": "폴란드어",
    "ps": "파슈토어",
    "pt": "포르투갈어",
    "qu": "케추아어",
    "rm": "레토로만어",
    "rn": "룬디어",
    "ro": "루마니아어",
    "ru": "러시아어",
    "rw": "르완다어",
    "sa": "산스크리트어",
    "sc": "사르디니아어",
    "sd": "신디어",
    "se": "북부 사미어",
    "sg": "산고어",
    "si": "스리랑카어",
    "sk": "슬로바키아어",
    "sl": "슬로베니아어",
    "sm": "사모아어",
    "sn": "쇼나어",
    "so": "소말리아어",
    "sq": "알바니아어",
    "sr": "세르비아어",
    "ss": "시스와티어",
    "st": "소토어 (남부)",
    "su": "순다어",
    "sv": "스웨덴어",
    "sw": "스와힐리어",
    "ta": "타밀어",
    "te": "텔루구어",
    "tg": "타지크어",
    "th": "태국어",
    "ti": "티그리냐어",
    "tk": "투르크멘어",
    "tl": "타갈로그어",
    "tn": "세츠와나어",
    "to": "통가어",
    "tr": "터키어",
    "ts": "총가어",
    "tt": "타타르어",
    "tw": "트위어",
    "ty": "타히티어",
    "ug": "위구르어",
    "uk": "우크라이나어",
    "ur": "우르두어",
    "uz": "우즈베크어",
    "ve": "벤다어",
    "vi": "베트남어",
    "vo": "볼라퓌크어",
    "wa": "왈론어",
    "wo": "올로프어",
    "xh": "코사어",
    "yi": "이디시어",
    "yo": "요루바어",
    "za": "주앙어",
    "zh": "중국어",
    "zu": "줄루어"
  }
}
