{
    "locale": "no",
    "languages": {
        "aa": "afar",
        "ab": "abkhasisk",
        "ae": "avestisk",
        "af": "afrikaans",
        "ak": "akan",
        "am": "amharisk",
        "an": "aragonesisk",
        "ar": "arabisk",
        "as": "assamesisk",
        "av": "avarisk",
        "ay": "aymara",
        "az": "aserbajdsjansk",
        "ba": "basjkirsk",
        "be": "hviterussisk",
        "bg": "bulgarsk",
        "bh": "bihari",
        "bi": "bislama",
        "bm": "bambara",
        "bn": "bengali",
        "bo": "tibetansk",
        "br": "bretonsk",
        "bs": "bosnisk",
        "ca": "katalansk",
        "ce": "tsjetsjensk",
        "ch": "chamorro",
        "co": "korsikansk",
        "cr": "cree",
        "cs": "tsjekkisk",
        "cu": "kirkeslavisk",
        "cv": "tsjuvasjisk",
        "cy": "walisisk",
        "da": "dansk",
        "de": "tysk",
        "dv": "dhivehi",
        "dz": "dzongkha",
        "ee": "ewe",
        "el": "gresk",
        "en": "engelsk",
        "eo": "esperanto",
        "es": "spansk",
        "et": "estisk",
        "eu": "baskisk",
        "fa": "persisk",
        "ff": "fulfulde",
        "fi": "finsk",
        "fj": "fijisk",
        "fo": "færøysk",
        "fr": "fransk",
        "fy": "frisisk",
        "ga": "irsk",
        "gd": "skotsk gælisk",
        "gl": "galisisk",
        "gn": "guaraní",
        "gu": "gujarati",
        "gv": "mansk",
        "ha": "hausa",
        "he": "hebraisk",
        "hi": "hindi",
        "ho": "hiri motu",
        "hr": "kroatisk",
        "ht": "haitisk kreolsk",
        "hu": "ungarsk",
        "hy": "armensk",
        "hz": "herero",
        "ia": "interlingua",
        "id": "indonesisk",
        "ie": "interlingue",
        "ig": "igbo",
        "ii": "yi",
        "ik": "inupiak",
        "io": "ido",
        "is": "islandsk",
        "it": "italiensk",
        "iu": "inuittisk",
        "ja": "japansk",
        "jv": "javanesisk",
        "ka": "georgisk",
        "kg": "kongo",
        "ki": "kikuya",
        "kj": "kwanyama",
        "kk": "kasakhisk",
        "kl": "grønlandsk",
        "km": "khmer",
        "kn": "kannada",
        "ko": "koreansk",
        "kr": "kanuri",
        "ks": "kasjmiri",
        "ku": "kurdisk",
        "kv": "syrjensk",
        "kw": "kornisk",
        "ky": "kirgisisk",
        "la": "latin",
        "lb": "luxembourgsk",
        "lg": "luganda",
        "li": "limburgsk",
        "ln": "lingala",
        "lo": "laotisk",
        "lt": "litauisk",
        "lu": "luba-katanga",
        "lv": "latvisk",
        "mg": "gassisk",
        "mh": "marshallesisk",
        "mi": "maorisk",
        "mk": "makedonsk",
        "ml": "malayalam",
        "mn": "mongolsk",
        "mr": "marathi",
        "ms": "malayisk",
        "mt": "maltesisk",
        "my": "burmesisk",
        "na": "naurisk",
        "nb": "bokmål",
        "nd": "nord-ndebele",
        "ne": "nepali",
        "ng": "ndonga",
        "nl": "nederlandsk",
        "nn": "nynorsk",
        "no": "norsk",
        "nr": "sør-ndebele",
        "nv": "navajo",
        "ny": "chichewa",
        "oc": "oksitansk",
        "oj": "ojibwa",
        "om": "oromo",
        "or": "oriya",
        "os": "ossetisk",
        "pa": "punjabi",
        "pi": "pali",
        "pl": "polsk",
        "ps": "pashto",
        "pt": "portugisisk",
        "qu": "quechua",
        "rm": "retoromansk",
        "rn": "kirundi",
        "ro": "rumensk",
        "ru": "russisk",
        "rw": "kinyarwanda",
        "sa": "sanskrit",
        "sc": "sardisk",
        "sd": "sindhi",
        "se": "nordsamisk",
        "sg": "sango",
        "si": "singalesisk",
        "sk": "slovakisk",
        "sl": "slovensk",
        "sm": "samoansk",
        "sn": "shona",
        "so": "somali",
        "sq": "albansk",
        "sr": "serbisk",
        "ss": "swati",
        "st": "sesotho",
        "su": "sundanesisk",
        "sv": "svensk",
        "sw": "swahili",
        "ta": "tamilsk",
        "te": "telugu",
        "tg": "tadsjikisk",
        "th": "thai",
        "ti": "tigrinya",
        "tk": "turkmensk",
        "tl": "tagalog",
        "tn": "tswana",
        "to": "tonganesisk",
        "tr": "tyrkisk",
        "ts": "tsonga",
        "tt": "tatarsk",
        "tw": "twi",
        "ty": "tahitisk",
        "ug": "uigurisk",
        "uk": "ukrainsk",
        "ur": "urdu",
        "uz": "usbekisk",
        "ve": "tshivenda",
        "vi": "vietnamesisk",
        "vo": "volapük",
        "wa": "vallonsk",
        "wo": "wolof",
        "xh": "xhosa",
        "yi": "jiddisk",
        "yo": "joruba",
        "za": "zhuang",
        "zh": "kinesisk",
        "zu": "zulu"
    }
}
