{
  "locale": "sk",
  "languages": {
    "aa": "Afar",
    "ab": "Abcházština",
    "ae": "Avestánština",
    "af": "Afrikánština",
    "ak": "Akanština",
    "am": "Amharčina",
    "an": "Aragónština",
    "ar": "Arabčina",
    "as": "Assamština",
    "av": "Avarština",
    "ay": "Ajmarština",
    "az": "Azerbajdžanština",
    "ba": "Baškirština",
    "be": "Běloruština",
    "bg": "Bulharština",
    "bh": "Bihárština",
    "bi": "Bislama",
    "bm": "Bambarština",
    "bn": "Bengálčina",
    "bo": "Tibetština",
    "br": "Bretonština",
    "bs": "Bosenština",
    "ca": "Katalánština",
    "ce": "Čečenština",
    "ch": "Chamorro",
    "co": "Korzicanština",
    "cr": "Cree",
    "cs": "Čeština",
    "cu": "Církevná slovančina",
    "cv": "Čuvaština",
    "cy": "Waleština",
    "da": "Dánština",
    "de": "Nemčina",
    "dv": "Maldivčina",
    "dz": "Dzongkha",
    "ee": "Ewe",
    "el": "Gréčtina (moderná)",
    "en": "Angličtina",
    "eo": "Esperanto",
    "es": "Španielčina",
    "et": "Estónština",
    "eu": "Baskičtina",
    "fa": "Perzština",
    "ff": "Fulah",
    "fi": "Fínčina",
    "fj": "Fidžianština",
    "fo": "Faerština",
    "fr": "Francúzština",
    "fy": "Západná frízština",
    "ga": "Írština",
    "gd": "Gaelčina",
    "gl": "Galícijština",
    "gn": "Guaraní",
    "gu": "Gudžarátština",
    "gv": "Manx",
    "ha": "Hauština",
    "he": "Hebrejčina (moderná)",
    "hi": "Hindčina",
    "ho": "Hiri Motu",
    "hr": "Chorvátština",
    "ht": "Haitská kreolčina",
    "hu": "Maďarština",
    "hy": "Arménština",
    "hz": "Herero",
    "ia": "Interlingua",
    "id": "Indonézština",
    "ie": "Interlingue",
    "ig": "Igbo",
    "ii": "Nuosu",
    "ik": "Inupiak",
    "io": "Ido",
    "is": "Islandčina",
    "it": "Taliančina",
    "iu": "Inuktitut",
    "ja": "Japončina",
    "jv": "Jávčina",
    "ka": "Gruzínština",
    "kg": "Kongo",
    "ki": "Kikuyu",
    "kj": "Kwanyama",
    "kk": "Kazaština",
    "kl": "Grónština",
    "km": "Stredokhmerská khmérčina",
    "kn": "Kannada",
    "ko": "Kórejčina",
    "kr": "Kanurí",
    "ks": "Kašmírština",
    "ku": "Kurdčina",
    "kv": "Komi",
    "kw": "Kornština",
    "ky": "Kyrgyzština",
    "la": "Latinčina",
    "lb": "Luxemburská nemčina",
    "lg": "Gandština",
    "li": "Limburština",
    "ln": "Lingala",
    "lo": "Laoština",
    "lt": "Litovčina",
    "lu": "Luba-Katanga",
    "lv": "Lotyština",
    "mg": "Malgaština",
    "mh": "Marshallovská ostrovná reč",
    "mi": "Maorčina",
    "mk": "Macedónština",
    "ml": "Malajálamčina",
    "mn": "Mongolčina",
    "mr": "Maráthčina",
    "ms": "Malajčina",
    "mt": "Maltčina",
    "my": "Barmština",
    "na": "Nauru",
    "nb": "Bokmål",
    "nd": "Severné ndebele",
    "ne": "Nepálčina",
    "ng": "Ndonga",
    "nl": "Holandčina",
    "nn": "Nórsky nynorsk",
    "no": "Nórština",
    "nr": "Južné ndebele",
    "nv": "Navajo",
    "ny": "Nyanja",
    "oc": "Okcitánština",
    "oj": "Ojibwa",
    "om": "Oromo",
    "or": "Oriyčina",
    "os": "Osetčina",
    "pa": "Paňdžábština",
    "pi": "Páli",
    "pl": "Poľština",
    "ps": "Paštština",
    "pt": "Portugalčina",
    "qu": "Kečuánština",
    "rm": "Rétorománština",
    "rn": "Rundčina",
    "ro": "Rumunčina",
    "ru": "Ruština",
    "rw": "Kinyarwanda",
    "sa": "Sanskrit",
    "sc": "Sardínčina",
    "sd": "Sindhština",
    "se": "Severná sámčina",
    "sg": "Sango",
    "si": "Sinhalčina",
    "sk": "Slovenčina",
    "sl": "Slovinčina",
    "sm": "Samojština",
    "sn": "Shona",
    "so": "Somálčina",
    "sq": "Albánčina",
    "sr": "Srbčina",
    "ss": "Svazi",
    "st": "Južná sothčina",
    "su": "Sundančina",
    "sv": "Švédština",
    "sw": "Svahilčina",
    "ta": "Tamiltina",
    "te": "Telugu",
    "tg": "Tadžičtina",
    "th": "Thajčina",
    "ti": "Tigriňa",
    "tk": "Turkménština",
    "tl": "Tagalog",
    "tn": "Tswana",
    "to": "Tonga",
    "tr": "Turečtina",
    "ts": "Tsonga",
    "tt": "Tatarština",
    "tw": "Twi",
    "ty": "Tahitianština",
    "ug": "Ujgurčina",
    "uk": "Ukrajinčina",
    "ur": "Urdu",
    "uz": "Uzbečtina",
    "ve": "Vendčina",
    "vi": "Vietnamčina",
    "vo": "Volapük",
    "wa": "Valónština",
    "wo": "Wolof",
    "xh": "Xhosa",
    "yi": "Jidiš",
    "yo": "Joruba",
    "za": "Chuangština",
    "zh": "Čínština",
    "zu": "Zulu"
  }
}
